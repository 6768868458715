import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";

import PanelLayout from "../components/PanelLayout";
import PersonalData from "../components/Panel/PersonalData";
import Turnos from "../components/Panel/Turnos";
import Imagenes from "../components/Panel/Imagenes";
import Facturas from "../components/Panel/Facturas";
import Tramites from "../components/Panel/Tramites";
import Medicamentos from "../components/Panel/Medicamentos";
import Autorizaciones from "../components/Panel/Autorizaciones";
import Contacto from "../components/Panel/Contacto";
import Laboratorios from "../components/Panel/Laboratorios";

const Panel = ({ auth, history, match, user }) => {
  useEffect(() => {
    if (!auth || !user) {
      history.push("/");
      return;
    }
  }, [auth, history, user]);

  return (
    <PanelLayout>
      <Switch>
        <Route exact path={match.path} component={PersonalData} />
        <Route path={`${match.path}/turnos`} component={Turnos} />
        <Route path={`${match.path}/laboratorios`} component={Laboratorios} />
        <Route path={`${match.path}/imagenes`} component={Imagenes} />
        <Route path={`${match.path}/facturas`} component={Facturas} />
        <Route path={`${match.path}/tramites`} component={Tramites} />
        <Route path={`${match.path}/medicamentos`} component={Medicamentos} />
        <Route
          path={`${match.path}/autorizaciones`}
          component={Autorizaciones}
        />
        <Route path={`${match.path}/contacto`} component={Contacto} />
      </Switch>
    </PanelLayout>
  );
};

const mapStateToProps = state => ({
  auth: state.auth.isAuthenticated,
  user: state.auth.user
});

export default connect(
  mapStateToProps,
  null
)(Panel);
