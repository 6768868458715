import React from "react";
import Tags from "../components/Meta/Tags";
import Banner from "../components/Banner";
import Content from "../components/Universal/Content";

import ImageBanner from "../assets/img/logo-universal.jpg";

const UniversalAssitance = () => (
  <>
    <Tags 
      title="Asistencia al viajero" 
      description="Universal assistance cuenta con el más completo servicio de asistencia durante tu viaje. " 
      keywords="asistencia al viajero, viajar, consultas medicas, cobertura en el exterior, medico a domicilio, voucher, perdida de equipaje, internacion, viaja protegido" 
      canonical="/universal-assistance" />
    <Banner
      title="Universal Assistance"
      image={ImageBanner}
      size="6"
      height="ua"
    />
    <Content />
  </>
);

export default UniversalAssitance;
