import {
  PROVINCES_LOADING,
  GET_PROVINCES_SUCCESS,
  GET_PROVINCES_FAILURE
} from "../actions/types/provinces";

const initialState = {
  data: [],
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PROVINCES_LOADING: {
      return {
        ...state,
        loading: true
      };
    }

    case GET_PROVINCES_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: [...action.payload.data]
      };
    }

    case GET_PROVINCES_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }

    case "RESET_MAP_REDUCER": {
      return {
        ...state,
        ...initialState
      };
    }

    default: {
      return state;
    }
  }
};
