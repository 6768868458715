import React from "react";
import { Col } from "reactstrap";
import PageWrapper from "../PageWrapper";

import Mail from "../../assets/img/mail.svg";
import Turnos from "../../assets/img/turnos.svg";
import Phone from "../../assets/img/phone-icon.svg";
import Lotus from "../../assets/img/lotus.svg";
import Map from "../../assets/img/maplocation.svg";

const Content = () => {
  return (
    <PageWrapper>
      <Col xs="12" className="p-0 mb-4">
        <p className="semi-bold-font">
          Todo en un solo lugar para verte y sentirte mejor.
        </p>
      </Col>
      <Col xs={12} sm={6}>
        <h5>Cosmetología</h5>
        
        <p className="mt-3 mb-1">Prácticas:</p>
        <ul className="ua_ul">
          <li className="ua_list_estetica">
            <img src={Lotus} alt="lotus icon" className="icons_ua" /> Limpieza
            de cutis
          </li>
          <li className="ua_list_estetica">
            <img src={Lotus} alt="lotus icon" className="icons_ua" />{" "}
            Dermoabrasión con peeling
          </li>
          <li className="ua_list_estetica d-flex">
            <div className="mr-1">
              <img src={Lotus} alt="lotus icon" className="icons_ua" />{" "}
            </div>
            <div>Limpieza de cutis con dermoabrasión</div>
          </li>
          <li className="ua_list_estetica">
            <img src={Lotus} alt="lotus icon" className="icons_ua" /> Dermoabrasión en cuello y escote con mascara
          </li>
          <li className="ua_list_estetica">
            <img src={Lotus} alt="lotus icon" className="icons_ua" /> Peeling
          </li>
        </ul>
      </Col>
      <Col xs={12} sm={6}>
        <h5>Podología</h5>
        
        <p className="mt-3 mb-1">Prácticas:</p>
        <ul className="ua_ul">
          <li className="ua_list_estetica">
            <img src={Lotus} alt="" className="icons_ua" /> Podología
          </li>
          <li className="ua_list_estetica">
            <img src={Lotus} alt="" className="icons_ua" /> Reflexologia
          </li>
        </ul>
      </Col>
      <Col xs={12} className="p-0">
      <p class="mb-1"> &nbsp; </p>
        <p class="mb-1">Para hacer una reserva consultar en:</p>
        <ul class="ua_ul">
          <li>
            <a href="mailto:turnosgaona@altasalud.com.ar">
              <img
                src={Mail}
                alt="mail icon"
                class="pr-1 centros-medicos__icons-info"
              />{" "}
              turnosgaona@altasalud.com.ar
            </a>
          </li>
          <li>
            <img
              src={Turnos}
              alt="turnos icon"
              class="pr-1 centros-medicos__icons-info"
            />
            <a
              class="links-inner-faqs"
              target="_blank"
              href="https://turnos.elijasuturno.com.ar/turnos_php2/?es=alta"
              rel="noopener noreferrer"
            >
              Turnos online
            </a>
          </li>
          <li>
            <a href="Tel: 4588-5900">
              <img
                src={Phone}
                alt="phone icon"
                class="pr-1 centros-medicos__icons-info"
              />
              4588-5900
            </a>
          </li>
        </ul>
        <p class="pt-3">
          Todas las prácticas se realizan en{" "}
          <br className="d-block d-md-none" />
          <img src={Map} alt="map icon" class="pr-1" />
          Av Gaona 3519/27.
        </p>
      </Col>
    </PageWrapper>
  );
};

export default Content;
