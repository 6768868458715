import axios from "axios";

const API_URL = process.env.REACT_APP_API;

const setTokenHeaders = token => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

export { setTokenHeaders, API_URL };
