import React, { useState } from "react";
import Corazon_no from "../../assets/img/Corazon_no.png";
import Corazon_si from "../../assets/img/Corazon_si.png";
import { connect, ReactReduxContext, useSelector } from "react-redux";

const SetFavouriteIcon = ({ props }) => {
  const [favorito, setFavorito] = useState(false);
  const handleClick = () => {
    setFavorito(!favorito);
  };

  const { useContext } = React;
  const { store } = useContext(ReactReduxContext);
  const usuario_data = useSelector((state) => state.auth.user);

  return (
    <span className="d-flex resultado-busqueda__text">
      <div className="icon_resultado mr-2">
        <img
          src={favorito ? Corazon_si : Corazon_no}
          onClick={handleClick}
          alt="Icono de favoritos"
        />
      </div>
    </span>
  );
};

export default SetFavouriteIcon;
