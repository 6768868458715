import React from "react";
import { Spinner } from "reactstrap";

const SubmitBtn = ({ color, className, text, loading }) => {
  switch (color) {
    case "orange": {
      return (
        <button type="submit" className={`d-block btn-orange ${className}`}>
          {loading ? <Spinner size="sm" color="light" /> : <>{text}</>}
        </button>
      );
    }

    default: {
      return null;
    }
  }
};

export default SubmitBtn;
