import React from "react";
import { Row, Col, CardHeader, CardBody } from "reactstrap";
import { Accordion, Button } from "react-bootstrap";

import PageWrapper from "../PageWrapper";
import PageTitle from "../PageTitle";
import CaretDown from "../../assets/img/caret-down-menu.svg";
import Phone from "../../assets/img/phone-icon.svg";
import Mail from "../../assets/img/mail.svg";

const Content = () => (
  <PageWrapper>
    <Col xs={12}>
      <Accordion>
        <div>
          <CardHeader className="faqs__card-header">
            <Accordion.Toggle
              as={Button}
              variant="link"
              eventKey="0"
              className="faqs__titles w-100"
            >
              Información general
              <img src={CaretDown} alt="caret down" className="pl-2" />
            </Accordion.Toggle>
          </CardHeader>

          <Accordion.Collapse eventKey="0">
            <CardBody>
              <Row className="mx-0">
                <Col xs={12}>
                  <PageTitle thin>Escuela de enfermería</PageTitle>
                  <p className="semi-bold-font">Fundamentación</p>
                  <p>
                    <span className="semi-bold-font">
                      La escuela de enfermería Bernardo Houssay a-1420
                    </span>
                    , fue creada el 1º de Marzo de 2004, por iniciativa del
                    actual personal docente de la escuela y el Director Médico
                    de ALTA SALUD, se dicta la Carrera de Tecnicatura Superior
                    de Enfermería, desde entonces cuenta con 380 egresados.
                  </p>
                  <p>
                    El ideario de esta creación fue formar profesionales que
                    brinden cuidados de enfermería integral y de calidad a las
                    personas, en cualquiera de las etapas de la vida, en los
                    distintos niveles de complejidad, en instituciones
                    hospitalarias y comunitarias.
                  </p>
                  <p>
                    Los contenidos curriculares de las unidades específicas se
                    orientan hacia la formación de profesionales idóneos para
                    dar cuidados a las personas, familia y comunidad mediante
                    una actitud humanística, ética y con responsabilidad.
                  </p>
                  <p>
                    Se enfatiza en los conocimientos teóricos y en la aplicación
                    práctica de intervención de enfermería en pacientes con
                    patologías cardiovasculares, con el objetivo de capacitar al
                    alumno para promover la salud, prevenir la enfermedad,
                    intervenir en el tratamiento, rehabilitación y recuperación
                    de la salud del paciente.
                  </p>
                  <p>
                    La modalidad de trabajo articula e integra vertical y
                    horizontalmente los saberes teóricos y la práctica de
                    enfermería en las distintas unidades hospitalarias, teniendo
                    en cuenta un enfoque integral del ser humano considerando
                    sus aspectos biológicos, psíquicos y sociales.
                  </p>
                </Col>
                <Col xs={12} className="mt-3">
                  <PageTitle thin>Instituto:</PageTitle>
                  <p className="semi-bold-font">
                    Escuela de enfermería Bernardo Houssay
                  </p>
                  <p>
                    <span className="semi-bold-font">Designación:</span> A-1420
                  </p>
                  <p>
                    <span className="semi-bold-font">
                      Denominación del plan de estudio
                    </span>
                    : Tecnicatura superior de enfermería
                  </p>
                  <p>
                    <span className="semi-bold-font">Norma aprobatoria</span>:
                    Resolución 189/SSPLINED/16
                  </p>
                </Col>
              </Row>
            </CardBody>
          </Accordion.Collapse>
        </div>

        <div>
          <CardHeader className="faqs__card-header">
            <Accordion.Toggle
              as={Button}
              variant="link"
              eventKey="1"
              className="faqs__titles w-100"
            >
              Contacto
              <img src={CaretDown} alt="caret down" className="pl-2" />
            </Accordion.Toggle>
          </CardHeader>

          <Accordion.Collapse eventKey="1">
            <CardBody>
              <Row className="mx-0">
                <Col className="mt-3">
                  <p>
                    <span className="semi-bold-font">Rector:</span> Lic.
                    Fernandez, Jorge Oscar
                    <br />
                    <span className="semi-bold-font">Secretaria:</span> Siscar,
                    María Cristina
                    <br />
                    <img
                      src={Phone}
                      alt="phone icon"
                      className="pr-2 centros-medicos__icons-info icon-align"
                    />
                    <a href="tel:45885800">4588-5800</a>
                    <br />
                    <img
                      src={Mail}
                      alt="mail icon"
                      className="pr-2 centros-medicos__icons-info icon-align"
                    />
                    <a href="mailto: escuelabhoussay@yahoo.com.ar">
                      escuelabhoussay@yahoo.com.ar
                    </a>
                  </p>
                </Col>
              </Row>
            </CardBody>
          </Accordion.Collapse>
        </div>

        <div>
          <CardHeader className="faqs__card-header">
            <Accordion.Toggle
              as={Button}
              variant="link"
              eventKey="2"
              className="faqs__titles w-100"
            >
              Currícula
              <img src={CaretDown} alt="caret down" className="pl-2" />
            </Accordion.Toggle>
          </CardHeader>

          <Accordion.Collapse eventKey="2">
            <CardBody>
              <Row className="mx-0">
                <Col className="mt-3">
                  <p>
                    <span className="semi-bold-font">
                      1º año 1º cuatrimestre:
                    </span>
                  </p>
                  <ul>
                    <li>Salud pública</li>
                    <li>Cuidados y modelos de atención</li>
                    <li>Biología</li>
                    <li>Sujeto de atención</li>
                    <li>Antropología filosófica</li>
                    <li>Pensamiento científico</li>
                    <li>Práctica profesionalizante I</li>
                  </ul>

                  <p className="pt-3">
                    <span className="semi-bold-font">
                      1º año 2º cuatrimestre:
                    </span>
                  </p>
                  <ul>
                    <li>Enfermería comunitaria</li>
                    <li>Epidemiología</li>
                    <li>Anatomía y fisiología</li>
                    <li>Nutrición y dietoterapia</li>
                    <li>Comunicación y relaciones interpersonales</li>
                    <li>Farmacología</li>
                    <li>Práctica profesionalizante II</li>
                  </ul>

                  <p className="pt-3">
                    <span className="semi-bold-font">
                      2º año 1º cuatrimestre:
                    </span>
                  </p>
                  <ul>
                    <li>Condiciones y medio ambiente de trabajo</li>
                    <li>Informática en salud</li>
                    <li>Enfermería del adulto y anciano</li>
                    <li>Fundamentos socioculturales de la enfermería</li>
                    <li>Técnicas de investigación</li>
                    <li>Práctica profesionalizante III</li>
                  </ul>

                  <p className="pt-3">
                    <span className="semi-bold-font">
                      2º año 2º cuatrimestre:
                    </span>
                  </p>
                  <ul>
                    <li>Gestión de servicios de enfermería</li>
                    <li>Proceso de atención en adultos y ancianos</li>
                    <li>Inglés</li>
                    <li>Aspectos psicosociales del cuidado</li>
                    <li>Principio de bioética</li>
                    <li>Enfermeria en cardiologia I</li>
                    <li>Práctica profesionalizante IV</li>
                  </ul>

                  <p className="pt-3">
                    <span className="semi-bold-font">
                      3º año 1º cuatrimestre
                    </span>
                  </p>
                  <ul>
                    <li>Enfermería materno infantil y del adolescente</li>
                    <li>Desarrollo profesional de enfermería</li>
                    <li>Bioética aplicada</li>
                    <li>Investigación en salud</li>
                    <li>Práctica profesionalizante v</li>
                  </ul>

                  <p className="pt-3">
                    <span className="semi-bold-font">
                      3º año 2º cuatrimestre
                    </span>
                  </p>
                  <ul>
                    <li>Enfermeria en cardiologia II</li>
                    <li>Deontologia y legislacion</li>
                    <li>Prácticas profesional en la comunidad</li>
                  </ul>
                </Col>
              </Row>
            </CardBody>
          </Accordion.Collapse>
        </div>
      </Accordion>
    </Col>
  </PageWrapper>
);

export default Content;
