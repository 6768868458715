import React, { useEffect, useState } from "react";
import Select from "react-select";

import { connect } from "react-redux";
import { getProvinces, resetLocations } from "../redux/actions/provinces";
import { getAreasByProvinceId } from "../redux/actions/areas";
import { customStyles } from "./styles/common";

const ProvincesSelect = ({
  getProvinces,
  data,
  loading,
  onSelect,
  getAreasByProvinceId,
  resetLocations
}) => {
  const [value, setValue] = useState(null);

  useEffect(() => {
    const getData = async () => await getProvinces();
    getData();
  }, [getProvinces]);

  return (
    <Select
      options={data}
      id="prov"
      value={value}
      onChange={value => {
        setValue(value);
        onSelect(value.value);
        getAreasByProvinceId(value.value);
        resetLocations();
      }}
      placeholder="Provincias"
      isLoading={loading}
      clearable={false}
      className="basic-single"
      classNamePrefix="custom-select"
      noOptionsMessage={() => "No se encontraron datos."}
      styles={customStyles}
    />
  );
};

const mapStateToProps = state => ({
  data: state.provinces.data,
  loading: state.provinces.loading
});

export default connect(mapStateToProps, {
  getProvinces,
  getAreasByProvinceId,
  resetLocations
})(ProvincesSelect);
