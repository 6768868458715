import axios from "axios";
import { toast } from "react-toastify";
import { API_URL } from "../api";
import {
  LOADING_MEDICAL_ENTITY,
  GET_MEDICAL_ENTITY_SUCCESS,
  GET_MEDICAL_ENTITY_FAILURE,
  SET_MEDICAL_ENTITY_PAGE,
  RESET_MEDICAL_ENTITY,
  SET_MEDICAL_SERVICE_ID_NAME,
  SET_MEDICAL_SERVICE_ID,
  SET_MEDICAL_SPECIALTY_ID,
  SET_LOCATIONS_ENTITY_ID,
  SET_AREA_ENTITY_ID,
  SET_PROVINCE_ENTITY_ID,
  // COMMENT: ADD LINE
  SET_MEDICAL_ENTITY_NAME,
} from "./types/medicalEntity";
import { LogOut } from "./auth";

const GetUrlSearchParams = () => {
  let params = new URLSearchParams(document.location.search);
  let data = {};

  data.provinceId_save = !params.get("provinceId_save")
    ? ""
    : params.get("provinceId_save");
  data.locationId_save = !params.get("locationId_save")
    ? ""
    : params.get("locationId_save");
  data.areaId_save = !params.get("areaId_save")
    ? ""
    : params.get("areaId_save");

  data.medicalSpecialtyId_save = !params.get("medicalSpecialtyId_save")
    ? ""
    : params.get("medicalSpecialtyId_save");
  data.medicalServiceId_save = !params.get("medicalServiceId_save")
    ? ""
    : params.get("medicalServiceId_save");
  data.medicalNameEntity_save = !params.get("medicalNameEntity_save")
    ? ""
    : params.get("medicalNameEntity_save");

  for (let ind in data) {
    if (data[ind] == "all" || data[ind] == "TODOS") {
      data[ind] = "";
    }
  }

  return data;
};

export const searchMedicalEntities =
  (filters, resetPage) => async (dispatch) => {
    dispatch({ type: LOADING_MEDICAL_ENTITY });

    if (!filters.user_data) {
      filters.user_data = {};
    }

    const urlParams = GetUrlSearchParams();

    try {
      const baseUrl = `${API_URL}/api/web/medical_entity`;
      const pagination = `?page=${resetPage ? "1" : filters.page}&pagination=5`;
      const userData = `&personal_id=${filters.user_data.personal_id}&personal_id_type=${filters.user_data.personal_id_type}`;

      let URL = `${baseUrl}${pagination}${userData}`;
      console.log("URL USERDATA--->", URL);

      // COMMENT: START CODE
      if (
        filters.medical_service_name &&
        filters.medical_service_name !== "TODOS"
      ) {
        URL = `${URL}${`&name=${filters.medical_service_name}`}`;
      }

      if (filters.medical_service_id && filters.medical_service_id != "all") {
        URL = `${URL}${`&medical_service_id=${filters.medical_service_id}`}`;
      }

      if (
        filters.medical_specialty_id &&
        filters.medical_specialty_id !== "all"
      ) {
        URL = `${URL}${`&medical_specialty_id=${filters.medical_specialty_id}`}`;
      }

      // COMMENT: END CODE
      if (filters.province_id) {
        URL = `${URL}${`&province_id=${filters.province_id}`}`;
      }

      if (filters.location_id) {
        URL = `${URL}${`&location_id=${filters.location_id}`}`;
      }

      if (filters.area_id) {
        URL = `${URL}${`&area_id=${filters.area_id}`}`;
      }

      // URL params:

      if (
        urlParams.medicalNameEntity_save != "" &&
        !filters.medical_service_name
      ) {
        URL = `${URL}${`&name=${urlParams.medicalNameEntity_save}`}`;
      }

      if (
        urlParams.medicalServiceId_save != "" &&
        !filters.medical_service_id
      ) {
        URL = `${URL}${`&medical_service_id=${urlParams.medicalServiceId_save}`}`;
      }

      if (
        urlParams.medicalSpecialtyId_save != "" &&
        !filters.medical_specialty_id
      ) {
        URL = `${URL}${`&medical_specialty_id=${urlParams.medicalSpecialtyId_save}`}`;
      }

      if (urlParams.provinceId_save != "" && !filters.province_id) {
        URL = `${URL}${`&province_id=${urlParams.provinceId_save}`}`;
      }

      if (urlParams.locationId_save != "" && !filters.location_id) {
        URL = `${URL}${`&location_id=${urlParams.locationId_save}`}`;
      }

      //console.log('URL:', URL);

      // End of URL params.

      const response = await axios.get(URL);

      dispatch({
        type: GET_MEDICAL_ENTITY_SUCCESS,
        payload: {
          medicalEntity: response.data.data,
          markers: response.data.data.map((item) => ({
            lat: item.lat,
            lng: item.lon,
            name: item.name,
            id: item.id,
          })),
          links: {
            first: response.data.links.first,
            last: response.data.links.last,
            next: response.data.links.next,
            prev: response.data.links.prev,
          },
          meta: {
            currentPage: response.data.meta.current_page,
            from: response.data.meta.from,
            to: response.data.meta.to,
            total: response.data.meta.total,
          },
        },
      });

      return response.status;
    } catch (error) {
      dispatch({ type: GET_MEDICAL_ENTITY_FAILURE });

      if (error.response && error.response.status === 422) {
        toast.error(error.response.data.message);
      } else if (error.response && error.response.status === 401) {
        toast.error(error.response.data.message);
        dispatch(LogOut());
      } else {
        toast.error(
          "Lo sentimos, ha sucedido un error inesperado. (Error 0010)"
        );
      }
      return error.response.status;
    }
  };

export const setPage = (page) => ({
  type: SET_MEDICAL_ENTITY_PAGE,
  payload: {
    page,
  },
});

export const setMedicalServiceId = (id) => ({
  type: SET_MEDICAL_SERVICE_ID,
  payload: {
    value: id,
  },
});

export const setMedicalServiceIdName = (id) => ({
  type: SET_MEDICAL_SERVICE_ID_NAME,
  payload: {
    value: id,
  },
});

export const setMedicalSpecialtyId = (id) => ({
  type: SET_MEDICAL_SPECIALTY_ID,
  payload: {
    value: id,
  },
});

export const setLocationId = (id) => ({
  type: SET_LOCATIONS_ENTITY_ID,
  payload: {
    value: id,
  },
});

export const setAreaId = (id) => ({
  type: SET_AREA_ENTITY_ID,
  payload: {
    value: id,
  },
});

export const setProvinceId = (id) => ({
  type: SET_PROVINCE_ENTITY_ID,
  payload: {
    value: id,
  },
});

export const resetReducer = () => ({
  type: RESET_MEDICAL_ENTITY,
});

// COMMENT: START CODE Dario
export const setMedicalNameEntity = (name) => ({
  type: SET_MEDICAL_ENTITY_NAME,
  payload: {
    value: name,
  },
});
// COMMENT: END CODE  Dario
