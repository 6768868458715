import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { FormGroup, FormFeedback, Input } from "reactstrap";
import Select from "react-select";
import { connect } from "react-redux";

import { Formik, Form } from "formik";
import * as Yup from "yup";

import { customStyles } from "./styles/common";
import RadioButton from "./RadioButton";
import SubmitBtn from "./SubmitBtn";

import { getFormTypes, resetFormTypes } from "../redux/actions/form_types";
import { getFormTypeByWord } from "../lib/getFormTypeBySlug";
import { addContactForm } from "../redux/actions/common";
import FormLoading from "./FormLoading";

const motivos = [
  { label: "Pérdida", value: "Pérdida" },
  { label: "Robo", value: "Robo" },
  { label: "Daños", value: "Daños" }
];

const PedidoCredencialForm = ({
  user,
  getFormTypes,
  addContactForm,
  formTypes,
  loading,
  history,
  resetFormTypes
}) => {
  const [formTypeId, setFormTypeId] = useState(null);

  useEffect(() => {
    // Necesita los form types para saber a cual ID pegarle, pero las opciones
    // Esta en la variable motivos en la linea 19.
    const fetchFormTypes = async () => await getFormTypes(true);

    fetchFormTypes();
  }, [getFormTypes]);

  useEffect(() => {
    if (formTypes.length > 0) {
      setFormTypeId(getFormTypeByWord("pedido", formTypes));
    }
  }, [formTypes]);

  useEffect(() => {
    return () => resetFormTypes();
  }, [resetFormTypes]);

  if (loading) return <FormLoading />;

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        name: user ? user.full_name : "",
        title: "Pedido de Credencial",
        motivo: "",
        radioGroup: "",
        message: "",
        contact_form_type_id: formTypeId || "",
        domicilio: "",
        phone: user ? user.phone : "",
        email: user ? user.email : ""
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Requerido."),
        motivo: Yup.string().required("Requerido."),
        radioGroup: Yup.string().required("Debe seleccionar alguna opción.")
      })}
      onSubmit={async (values, { setSubmitting }) => {
        const parsedFormFields = {
          name: values.name,
          title: values.title,
          contact_form_type_id: values.contact_form_type_id,
          message: `Motivo: ${values.motivo.label}. <br />
          Tipo de Retiro: <br />
          ${
            values.radioGroup === "Retirar por oficina"
              ? "Retirar por oficina"
              : `Recibir en mi domicilio <br /> Domicilio: ${values.domicilio}`
          }
          `,
          phone: values.phone,
          email: values.email
        };

        const response = await addContactForm(parsedFormFields);

        setSubmitting(false);

        if (response === 200) {
          history.push("/panel/tramites");
        }
      }}
    >
      {({
        touched,
        errors,
        isSubmitting,
        handleBlur,
        values,
        handleChange,
        setFieldValue
      }) => (
        <Form className="mt-4">
          <fieldset disabled={isSubmitting} aria-busy={isSubmitting}>
            <FormGroup>
              <span className="d-block mt-4 mb-2 label-select-tramite">
                Motivo de pedido credencial
              </span>
              <Select
                className={`${
                  errors.motivo && touched.motivo ? "select-error" : ""
                }`}
                classNamePrefix="custom-select"
                placeholder="Seleccione un motivo"
                styles={customStyles}
                options={motivos}
                value={values.motivo}
                onChange={value => {
                  setFieldValue("motivo", value);
                }}
              />
              {errors.motivo && touched.motivo && (
                <FormFeedback className="d-block">{errors.motivo}</FormFeedback>
              )}
            </FormGroup>

            <FormGroup>
              <RadioButton
                name="radioGroup"
                id="Retirar por oficina"
                label="Retirar por oficina"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.radioGroup}
              />

              <RadioButton
                name="radioGroup"
                id="Recibir en mi domicilio"
                label="Recibir en mi domicilio"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.radioGroup}
              />
              {errors.radioGroup && touched.radioGroup && (
                <FormFeedback className="d-block">
                  {errors.radioGroup}
                </FormFeedback>
              )}
            </FormGroup>

            {values.radioGroup === "Retirar por oficina" && (
              <FormGroup className="mt-5">
                <h6 className="label-select-tramite">Datos de retiro</h6>
                <span className="d-block">Lunes a Viernes de 9 a 17hs</span>
                <span className="d-block">
                  Av. Gaona 3519/27, C1416DSX CABA.
                </span>
                <span className="d-block">
                  Centro de atención al cliente: 4588-5900
                </span>
              </FormGroup>
            )}

            {values.radioGroup === "Recibir en mi domicilio" && (
              <FormGroup className="mt-5">
                <h6 className="label-select-tramite">Ingrese domicilio</h6>
                <Input
                  name="domicilio"
                  type="textarea"
                  value={values.domicilio}
                  onChange={handleChange}
                  className="form-control__round mb-3"
                  placeholder="Ej: Godor. Valentin Vergara 1485 - Vicente López - CP1685"
                />
                <small className="form-text text-muted">
                  Recibirá su credencial dentro de los 12 días hábiles desde la
                  fecha de su pedido.
                </small>
              </FormGroup>
            )}

            <SubmitBtn
              color="orange"
              className="mt-4"
              text="Enviar"
              loading={isSubmitting}
            />
          </fieldset>
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user,
  formTypes: state.formTypes.formTypes,
  loading: state.formTypes.loading
});

export default withRouter(
  connect(mapStateToProps, {
    getFormTypes,
    addContactForm,
    resetFormTypes
  })(PedidoCredencialForm)
);
