import axios from "axios";
import { toast } from "react-toastify";
import { API_URL } from "../api";
import {
  LOADING_TURNOS,
  GET_TURNOS_SUCCESS,
  GET_TURNOS_FAILURE,
  SET_TURNOS_PAGE,
  RESET_TURNOS
} from "./types/turnos";
import { LogOut } from "./auth";

export const getTurnos = filters => async dispatch => {
  dispatch({ type: LOADING_TURNOS });

  try {
    const baseUrl = `${API_URL}/api/web/member/appointments`;
    const pagination = `?page=${filters.page}`;

    let URL = `${baseUrl}${pagination}`;

    const response = await axios.get(URL);

    if (response.status === 200) {
      dispatch({
        type: GET_TURNOS_SUCCESS,
        payload: {
          turnos: response.data.data,
          links: {
            first: response.data.links.first,
            last: response.data.links.last,
            next: response.data.links.next,
            prev: response.data.links.prev
          },
          meta: {
            currentPage: response.data.meta.current_page,
            from: response.data.meta.from,
            to: response.data.meta.to,
            total: response.data.meta.total
          }
        }
      });
    }

    return response;
  } catch (error) {
    dispatch({ type: GET_TURNOS_FAILURE });

    if (error.response && error.response.status === 422) {
      toast.error(error.response.data.message);
    } else if (error.response && error.response.status === 401) {
      toast.error(error.response.data.message);
      dispatch(LogOut());
    } else {
      toast.error("Lo sentimos, ha sucedido un error inesperado.  (Error 0014)");
    }
    return error;
  }
};

export const setPage = page => ({
  type: SET_TURNOS_PAGE,
  payload: {
    page
  }
});

export const resetTurnos = () => ({
  type: RESET_TURNOS
});
