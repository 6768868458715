import {
  GET_SERVICES_LOADING,
  GET_SERVICES_SUCCESS,
  GET_SERVICES_FAILURE
} from "../actions/types/medicalServices";

const initialState = {
  data: [],
  loading: true
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SERVICES_LOADING: {
      return {
        ...state,
        loading: true
      };
    }

    case GET_SERVICES_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: [...action.payload.data]
      };
    }

    case GET_SERVICES_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};
