import axios from "axios";
import { toast } from "react-toastify";
import { API_URL } from "../api";
import {
  PROVINCES_LOADING,
  GET_PROVINCES_SUCCESS,
  GET_PROVINCES_FAILURE
} from "./types/provinces";
import { RESET_LOCATIONS } from "./types/locations";

export const getProvinces = () => async dispatch => {
  dispatch({ type: PROVINCES_LOADING });

  try {
    const baseUrl = `${API_URL}/api/web/provinces`;
    const pagination = `?pagination=30`;

    let URL = `${baseUrl}${pagination}`;

    const response = await axios.get(URL);

    dispatch({
      type: GET_PROVINCES_SUCCESS,
      payload: {
        data: response.data.data.map(item => ({
          value: item.id,
          label: item.name
        }))
      }
    });

    return response.status;
  } catch (error) {
    dispatch({ type: GET_PROVINCES_FAILURE });

    if (error.response && error.response.status === 422) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Lo sentimos, ha sucedido un error inesperado.  (Error 0013)");
    }
    return error.response.status;
  }
};

export const resetLocations = () => ({
  type: RESET_LOCATIONS
});
