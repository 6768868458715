import React, { useEffect, useState, useRef } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

const DatePicker = ({ value, onChange, active, closeModal }) => {
  const [visible, setVisible] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    if (active) {
      setVisible(true);
    }

    if (!active) {
      setTimeout(() => {
        setVisible(false);
      }, 100);
    }
  }, [active]);

  useEffect(() => {
    if (active) {
      function handleClickOutside(event) {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          closeModal();
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [wrapperRef, active]);

  return (
    <div
      className="position-absolute"
      style={{
        opacity: visible ? 1 : 0,
        visibility: visible ? "visible" : "hidden",
        zIndex: 20,
        top: 0,
        left: 0,
      }}
      ref={wrapperRef}
    >
      <Calendar
        onChange={(date) => onChange(date)}
        value={value.length === 0 ? new Date() : value}
      />
    </div>
  );
};

export default DatePicker;
