import React from "react";
import { Row, Col } from "reactstrap";

import PanelTitle from "../PanelTitle";
import Icon from "../../assets/img/contacto.svg";

const Contacto = () => (
  <>
    <PanelTitle icon={Icon}>Contacto</PanelTitle>
    <Row className="mt-3 mt-sm-5">
      <Col xs={12} sm={6}>
        <h5 className="contact__title">Dirección</h5>
        <p className="contatc__p">Av. Gaona 3519/27, C1416DSX CABA</p>
        <h5 className="contact__title">Teléfonos</h5>
        <p className="contatc__p">
          Centro de atención al cliente: 4588-5900
          <br />
          Urgencias y emergencias 24hs: 0810-999-2628
          <br />
          Clínica Alta Salud: 4588-5800
          <br />
          Ventas: 0800-333-2582
        </p>
        <h5 className="contact__title">Email</h5>
        <a href="mailto: afiliaciones@gmail.com" className="contatc__p">
          afiliaciones@altasalud.com.ar
        </a>
      </Col>
    </Row>
  </>
);

export default Contacto;
