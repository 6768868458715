import React from "react";
import Header from "../components/Home/Header";
import Tags from "../components/Meta/Tags";
import Services from "../components/Home/Services";
import MedicCenters from "../components/Home/MedicCenters";
import Portals from "../components/Home/Portals";

const Home = () => (

  <>
    <Tags 
    title="Prepaga de salud" 
    description="Alta salud reafirma su vocación de servicio con calidad y responsabilidad, para que usted reciba la calidez en el trato y el profesionalismo que se merece. " 
    keywords="Prepaga de salud, Obra social, Cobertura medica, Prestadores medicos,Atencion primaria, Guardia, Servicios medicos, Turnos online, Portal de gestion, Centros medicos propios, Planes de salud, Cartilla medica" 
    canonical="/" />
    
    <Header />
    <Services />
    <MedicCenters />
    <Portals /> 
  </>
);

export default Home;
