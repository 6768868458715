import React from "react";
import { Col } from "reactstrap";
import Button from "../Button";
import { LazyLoadImage } from "react-lazy-load-image-component";

//Agrego Dario 
import ReactGA from 'react-ga';
ReactGA.initialize('UA-173013637-1');
ReactGA.pageview(window.location.pathname + window.location.search);

export const Event = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label
  });
};
//



const PortalItem = ({ title, text, image, url, external }) => {
  return (
    <Col xs="12" sm="6" className="px-5 mb-4">
      <LazyLoadImage
        src={image}
        alt="portal"
        className="w-100"
        loading="lazy"
        effect="blur"
      />
      <h2 className="grey-titles my-4">{title}</h2>
      <p>{text}</p>
      <Button type="grey-outline" onClick={()=> 	Event("Ingrese", "Logeo", "label")}  url={url} external={external}>
        Ingresar
      </Button>
    </Col>
  );
};

export default PortalItem;
