import React from "react";
import { Badge } from "reactstrap";
import moment from "moment";
import Download from "../../assets/img/download.svg";

const ImagenesItem = ({
  FechReali,
  Servicio,
  Estado,
  Link_PDF,
  Link_Imagenes
}) => {
  return (
    <tr>
      <td>{moment(FechReali).format("DD-MM-YYYY")}</td>
      <td>{Servicio}</td>
      <td>
        <Badge
          color={`${Estado !== "Finalizado" ? "warning" : "success"}`}
          pill
        >
          {Estado}
        </Badge>
      </td>
      <td>
        <a href={Link_PDF} target="_blank" rel="noopener noreferrer">
          {" "}
          <img src={Download} alt="printer" className="print-icon-tables" />
          PDF
        </a>
      </td>
      <td>
        <a href={Link_Imagenes} target="_blank" rel="noopener noreferrer">
          {" "}
          <img src={Download} alt="printer" className="print-icon-tables" />
          Imagenes
        </a>
      </td>
    </tr>
  );
};

export default ImagenesItem;
