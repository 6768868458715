import React from "react";
import SignIn from "../components/SignIn";

const Login = () => (
  <>
    <SignIn />
  </>
);

export default Login;
