import React from "react";
import { Col } from "reactstrap";
import { Link } from "react-router-dom";

const MedicCenterItem = ({ title, image }) => (
  <Col className="mb-4 mb-sm-0 text-center" xs="12" sm="3">
    <div className="home__circular--landscape">
      <img src={image} alt="medics center" className="home__images-round" />
    </div>
    <Link to="/centros-medicos">
      <h5 className="mt-3 centros__titulos">{title}</h5>
    </Link>
  </Col>
);

export default MedicCenterItem;
