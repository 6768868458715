export const LOADING_MEDICAL_ENTITY = "LOADING_MEDICAL_ENTITY";
export const GET_MEDICAL_ENTITY_SUCCESS = "GET_MEDICAL_ENTITY_SUCCESS";
export const GET_MEDICAL_ENTITY_FAILURE = "GET_MEDICAL_ENTITY_FAILURE";
export const SET_MEDICAL_ENTITY_PAGE = "SET_MEDICAL_ENTITY_PAGE";
export const RESET_MEDICAL_ENTITY = "RESET_MEDICAL_ENTITY";
export const SET_MEDICAL_SERVICE_ID = "SET_MEDICAL_SERVICE_ID";
export const SET_MEDICAL_SERVICE_ID_NAME = "SET_MEDICAL_SERVICE_ID_NAME";
export const SET_MEDICAL_SPECIALTY_ID = "SET_MEDICAL_SPECIALTY_ID";
export const SET_LOCATIONS_ENTITY_ID = "SET_LOCATIONS_ENTITY_ID";
export const SET_AREA_ENTITY_ID = "SET_AREA_ENTITY_ID";
export const RESET_PAGE_ON_SUBMIT = "RESET_PAGE_ON_SUBMIT";
export const SET_PROVINCE_ENTITY_ID = "SET_PROVINCE_ENTITY_ID";
// COMMENT: ADD LINE
export const SET_MEDICAL_ENTITY_NAME = "SET_MEDICAL_ENTITY_NAME";