import { combineReducers } from "redux";
import auth from "./auth";
import pharmacies from "./pharmacies";
import medicalEntity from "./medicalEntity";
import medicalServices from "./medicalServices";
import specialties from "./specialties";
import provinces from "./provinces";
import locations from "./locations";
import areas from "./areas";
import common from "./common";
import turnos from "./turnos";
import laboratorios from "./laboratorios";
import imagenes from "./imagenes";
import facturas from "./facturas";
import formTypes from "./form_types";
  // COMMENT: ADD LINE Dario
import medicalNameEntities from "./medicalNameEntities";
import pharmaciesName from "./pharmaciesName";

export default combineReducers({
  common,
  auth,
  pharmacies,
  medicalEntity,
  medicalServices,
  specialties,
  provinces,
  areas,
  locations,
  turnos,
  laboratorios,
  imagenes,
  facturas,
  formTypes,
  // COMMENT: ADD LINE Dario
  medicalNameEntities,
  pharmaciesName
});
