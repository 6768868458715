import React, { useEffect } from "react";
import { FormGroup, FormFeedback, Input } from "reactstrap";
import Select from "react-select";

import { connect } from "react-redux";
import { getFormTypes } from "../../redux/actions/form_types";
import { addContactForm } from "../../redux/actions/common";

import { Formik, Form } from "formik";
import * as Yup from "yup";

import SubmitBtn from "../SubmitBtn";
import { customStyles } from "../styles/common";
import FormLoading from "../FormLoading";

//Agrego Dario 
import ReactGA from 'react-ga';
ReactGA.initialize('UA-173013637-1');
ReactGA.pageview(window.location.pathname + window.location.search);

export const Event = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label
  });
};
//


const ContactForm = ({ getFormTypes, loading, formTypes, addContactForm }) => {
  
  const [formik, setFormik] = React.useState();
  const formikRef = (node) => {
    
    if (node !== null) {
      setFormik(node);
    }
  };

  useEffect(() => {
    const getData = async () => await getFormTypes(false, true);

    getData();
  }, [getFormTypes]);

  if (loading) return <FormLoading />;

  //dario agerga


  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        name: "",
        title: "Formulario de Contactos General",
        phone: "",
        message: "",
        email: "",
        contact_form_type_id: "",
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email()
          .typeError("Debe ser un email valido.")
          .required("Requerido."),
        message: Yup.string().required("Requerido."),
        name: Yup.string().required("Requerido."),
        contact_form_type_id: Yup.string().required("Requerido."),
        phone: Yup.string().required("Requerido")
      })}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        const parsedData = {
          name: values.name,
          title: values.title,
          phone: values.phone,
          message: values.message,
          email: values.email,
          contact_form_type_id: values.contact_form_type_id.value
        };

        const response = await addContactForm(parsedData);
        setSubmitting(false);

        if (response === 200) {
          resetForm({
            name: "",
            title: "Formulario de Contactos General",
            phone: "",
            message: "",
            email: "",
            contact_form_type_id: ""
          });
        }
      }}
    >
      {({
        touched,
        errors,
        isSubmitting,
        handleBlur,
        values,
        handleChange,
        setFieldValue
      }) => (
        <Form className="mt-4">
          <fieldset disabled={isSubmitting} aria-busy={isSubmitting}>
            <FormGroup>
              <Input
                type="text"
                name="name"
                className={`form-control__round ${errors.name &&
                  touched.name &&
                  "is-invalid"}`}
                onBlur={handleBlur}
                onChange={e => {
                  setFieldValue("name", e.target.value);
                }}
                value={values.name}
                placeholder="Nombre"
              />
              {errors.name && touched.name && (
                <FormFeedback className="d-block">{errors.name}</FormFeedback>
              )}
            </FormGroup>

            <FormGroup>
              <Input
                type="text"
                name="email"
                className={`form-control__round ${errors.email &&
                  touched.email &&
                  "is-invalid"}`}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                placeholder="Email"
              />
              {errors.email && touched.email && (
                <FormFeedback className="d-block">{errors.email}</FormFeedback>
              )}
            </FormGroup>

            <FormGroup>
              <Input
                type="text"
                name="phone"
                className={`form-control__round ${errors.phone &&
                  touched.phone &&
                  "is-invalid"}`}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.phone}
                placeholder="Teléfono"
              />
              {errors.phone && touched.phone && (
                <FormFeedback className="d-block">{errors.phone}</FormFeedback>
              )}
            </FormGroup>

            <FormGroup>
              <Select
                ref={formikRef}
                className={`${
                  errors.contact_form_type_id && touched.contact_form_type_id
                    ? "select-error"
                    : ""
                }`}
                classNamePrefix="custom-select"
                placeholder="Selecciona un motivo de contacto"
                styles={customStyles}
                options={formTypes}
                value={values.contact_form_type_id}
                onChange={value => {
                  setFieldValue("contact_form_type_id", value);
                }}
              />
              {errors.contact_form_type_id && touched.contact_form_type_id && (
                <FormFeedback className="d-block">
                  {errors.contact_form_type_id}
                </FormFeedback>
              )}
            </FormGroup>

            <FormGroup>
              <Input
                type="textarea"
                name="message"
                className={`form-control__round ${errors.message &&
                  touched.message &&
                  "is-invalid"}`}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.message}
                placeholder="Nombre, fecha de prestación, teléfono de contacto y comentarios..."
              />
              {errors.message && touched.message && (
                <FormFeedback className="d-block">
                  {errors.message}
                </FormFeedback>
              )}
            </FormGroup>
      
            <SubmitBtn
            onClick={()=> 
              Event("Contacto", "Enviar", "label")
            } 
              color="orange"
              className="mt-4"
              text="Enviar"
              loading={isSubmitting}
            />
              
          </fieldset>
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = state => ({
  formTypes: state.formTypes.formTypes,
  loading: state.formTypes.loading
});

export default connect(mapStateToProps, {
  getFormTypes,
  addContactForm
})(ContactForm);
