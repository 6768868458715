import React from "react";

import { Route, Switch } from "react-router-dom";
import TramitesPanel from "./TramitesPanel";
import PedidoCredencial from "./PedidoCredencial";
import UpdatePersonalData from "./UpdatePersonalData";
import SolicitudInfo from "./SolicitudInformacion";
import SugerenciasMejoras from "./SugerenciasMejoras";

const Tramites = ({ match }) => {
  return (
    <>
      <Switch>
        <Route exact path={match.path} component={TramitesPanel} />
        <Route
          path={`${match.path}/pedido-credencial`}
          component={PedidoCredencial}
        />
        <Route
          path={`${match.path}/actualizacion-datos-personales`}
          component={UpdatePersonalData}
        />
        <Route
          path={`${match.path}/solicitud-informacion`}
          component={SolicitudInfo}
        />
        <Route
          path={`${match.path}/sugerencias-mejoras`}
          component={SugerenciasMejoras}
        />
      </Switch>
    </>
  );
};

export default Tramites;
