import React from "react";
import DocumentMeta from 'react-document-meta';

/* 
 <Tags 
    title="" 
    description="" 
    keywords="" 
    canonical="/" />

*/

class Tags extends React.Component {
    render() {
      const title = this.props.title;
      const description=this.props.description;
      const canonical=this.props.canonical;
      const keywords=this.props.keywords;
      const meta = {
        title: title+' | Alta Salud',
        description: description,
        canonical: 'https://altasalud.com.ar'+canonical,
        meta: {
          charset: 'utf-8',
          name: {
            keywords: keywords
          }
        }
      };
  
      return (
        <DocumentMeta {...meta}>
          
        </DocumentMeta>
      );
    }
  }

export default Tags;
