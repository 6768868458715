import React from "react";
import { Row, Col } from "reactstrap";

import PageTitle from "../PageTitle";
import PageWrapper from "../PageWrapper";
import Check from "../../assets/img/check-circle-regular.svg";

const Reglamento = () => {
  return (
    <PageWrapper>
      <Col className="p-0">
        <Row>
          <Col xs="12">
            <h4>
              Reglamento Residencia de Clínica Médica “Clínica Alta Salud”
            </h4>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col xs="12">
            <PageTitle>Capítulo 1 Disposiciones Generales.</PageTitle>
            <h6>Artículo 1.</h6>
            <p className="mb-4">
              El sistema de Residencia de Clínica Médica le da la bienvenida a
              este centro donde durante los próximos 4 años el profesional
              médicorealizarà un programa de entrenamiento teórico-pràctico en
              la Especialidad de Clínica Médica, con un curso superior de
              Medicina Interna desarrollado en la Asociaciòn de Medicina Interna
              de Buenos Aires y auspiciado por el Sanatorio y al final de este
              entrenamiento recibirà el título de Especialista en Medicina
              Interna por intermedio de la AsociaciònMédica Argentina (AMA) que
              es equivalente al título de especialista en Clínica Médica que
              otorga el ministerio de salud de Argentina.
            </p>
            <h6 className="pt-3">Artículo 2.</h6>
            <p className="mb-4">
              El propósito de la residencia es entrenar un médico especialista
              en Clínica Médica, altamente capacitado para el manejo de la
              patología aguda y crónica del paciente adulto y del seguimiento y
              control de la misma; a su vez tendrá el perfil de ser el médico
              que defina en conjunto con las distintas especialidades Médicas y
              quirúrgicas el manejo y seguimiento del paciente.
            </p>
            <h6 className="pt-3">Artículo 3.</h6>
            <p className="mb-3">
              Se entenderá por Residente el profesional médico que hubiere
              cumplido con los requisitos para integrar el sistema de residencia
              según lo determinado por la Clínica Alta Slaud a saber.
            </p>
            <ul className="list-unstyled">
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>Presentar Curriculum vitae.</span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>
                  Médico argentino o extranjero con matrícula habilitante
                  (excluyente).
                </span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>Edad hasta 35 años (no excluyente).</span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>Presentar examen a concurso de residencia.</span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>Entrevista personal.</span>
              </li>
            </ul>
            <p className="mb-4">
              Será beneficiado con una beca de capacitación para desarrollar el
              curso superior de Medicina Interna de la AMA, y desarrollarà con
              el sanatorio actividad de tiempo completo y dedicación exclusiva;
              siendo incompatible con otro tipo de actividad laboral;percibirá
              mensualmente una bonificación por parte del Sanatorio.
            </p>
            <p className="mb-5">
              El médico concurrente es un profesional no remunerado que cumple
              con iguales funciones que el médico Residente, tendrá derecho a 1
              día libre y se le exige 1 guardia semanal, su tiempo de
              entrenamiento será 5 años.
            </p>
            <PageTitle>
              Capítulo 2: Organizaciòn Residencia Clínica Médica.
            </PageTitle>
            <h6>Artículo 4</h6>
            <p>
              El régimen de entrenamiento es régimen de tipo piramidal en el
              cual el residente se deberá desenvolver bajo estrictos principios
              de respeto personal y académico la residencia de Clínica Médica
              depende de la dirección de  Clínica Alta Slaud a cargo del
              Dr. Osmar Cavuoti y un comité de docencia que està constituido
              por:
            </p>
            <ul className="list-unstyled">
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>Jefe servicio Clínica Médica Dra Marisa Lòpez.</span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>Jefe planta 2º piso Dr. Pablo Rios.</span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>Jefe de instructor de residentes.</span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>Comité de docencia de la Clínica Alta Slaud</span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>Director médico en Clínica Alta Slaud.</span>
              </li>
            </ul>

            <h6 className="mt-5">Artículo 6.</h6>
            <p>
              El ciclo formativo iniciarà el 1º de junio y finalizará el 31 de
              mayo del año siguiente,el horario de actividades será de lunes a
              viernes de 8AM a 5PM y sàbados de 8AM a 1PM; deberá cumplir
              íntegramente las tareas antes de retirarse y dicho horario puede
              ser modificado según las circunstancias por el jefe de residentes.
            </p>
            <p className="mb-3">
              Las actividades se inician con un pase de guardia en la oficina de
              residentes en el cual asistirán los jefes de servicio, residentes,
              concurrentes, médicos de planta, inicia 8AM.
            </p>
            <p className="mb-3">
              Posteriormente revisión de los pacientes, con examen físico por
              parte de los residentes con la supervisión del residente superior,
              o en su defecto jefe de servicio o jefe de residentes. Recorrida
              general por las salas donde se tomarán conductas y se determinan
              pautas a seguir en el manejo del paciente, se solicitarán estudios
              diagnósticos,laboratorios,interconsultas; dichas órdenes serán
              elaboradas por el residente de 1 año con la supervisión del
              residente superior.
            </p>
            <p className="mb-3">
              En la jornada de la tarde se realizarà actividad teórica
              correspondiente a Clases teóricas,Talleres,Cita bibliográfica con
              el jefe de residentes y el pase de guardia de la tarde; quedando
              posteriormente encargados los residentes en guardia, con la
              disponibilidad de jefe de residentes y/o jefe de servicio.
            </p>
            <h6 className="mt-5">Artículo 7</h6>
            <p className="mb-3">
              En cuanto a las normativas del pase de guardia se iniciarà a las
              8AM(tolerancia 30 minutos) a partir de ese momento se considerarà
              llegada tarde a la actividad; durante el pase de guardia el
              residente de 1 año presenta el paciente y el residente superior
              realizarà una evaluación y análisis de las conductas tomadas.
              Durante el pase de guardia se solicita a los residentes mantener
              apagados los equipos de telefonía móvil (mantenerlos en modo
              vibratorio) de tal forma que no alteren la mecánica del pase de
              guardia; igual recomendación se hace durante la recorrida de
              sala,pase de la tarde y actividades acadèmicas,se debe guardar
              compostura y respeto hacia el colega que presenta el paciente; no
              se podrá ingerir alimentos, consumo de matè, café, no fumar y
              guardar una postura corporal acorde a la importancia del acto
              mèdico. La presentación personal debe ser prolija, se exige ambo
              y/o guardapolvo blanco encima de la ropa de vestir y deberá contar
              con los implementos estetoscopio, lámpara de luz, martillo de
              reflejos, equipo de órganos, lapicera, sello.
            </p>
            <p className="mb-3">
              El residente de 1 año deberá cumplir en promedio 8=10 guardias
              mensuales, el residente de 2 año deberá cumplir promedio de 8
              guardias al mes. El jefe de residentes publicará al inicio de cada
              mes el cronograma de guardias de los residentes; cualquier cambio
              de guardia deberá ser notificado al jefe de residentes sin
              excepción, el abandono de guardia se considerará falta grave; si
              durante una guardia el residente presenta algún percance de salud,
              deberá acudir a la guardia del Sanatorio que funciona las 24 hrs y
              será evaluado por el médico de guardia (jefe de guardia del
              sanatorio y superior administrativo del residente en ese momento)
              y este determinará si el residente está en condiciones de
              proseguir o no con la guardia; esta situacion debera ser
              notificada de inmediato al jefe de residentes, no está permitido
              durante la guardia abandonar las instalaciones del sanatorio y
              mucho menos dejar el lugar de la guardia reemplazado por otro
              residente sin dar previo aviso, se considerará falta grave.
            </p>
            <p className="mb-3">
              En caso de un evento de fuerza mayor por el cual el residente no
              pueda presentarse a realizar su guardia, el jefe de residente
              tiene la facultad de solicitar colaboración de un residente del
              mismo nivel para la realización de dicha guardia y en caso de no
              haber voluntarios el jefe de residentes podrá designar según su
              criterio quien hace la guardia,posteriormente el residente se le
              compensará por dicha guardia extra una vez. En caso de un evento
              de fuerza mayor por el cual el residente no pueda presentarse a
              realizar su guardia,el jefe de residente tiene la facultad de
              solicitar colaboración de un residente del mismo nivel para la
              realización de dicha guardia y en caso de no haber voluntarios el
              jefe de residentes podrá designar según su criterio quien hace la
              guardia,posteriormente el residente se le compensará por dicha
              guardia extra una vez el residente afectado se recupere, y este
              deberá presentar certificado médico que lo respalde y/o
              certificado que sustente el evento de fuerza mayor.
            </p>
            <p className="mb-3">
              Goza de una licencia anual de quince(15) días corridos en el
              periodo de verano; estos podrán ser tomados en forma corrida en
              una única vez si la organización del servicio de clínica médica lo
              permite de acuerdo a las circunstancias del momento o ser
              repartidos entre el periodo de verano y periodo invernal. Gozar de
              licencia de matrimonio (7 días corridos), maternidad (90 días) y/o
              paternidad (3 días corridos), por razones de salud justificadas,
              fallecimiento familiar en primer grado (5 días corridos),
              enfermedad profesional o accidente de trabajo (lo que
              corresponda).
            </p>

            <h6 className="mt-5">Artículo 8.</h6>
            <p className="mb-3">
              La renuncia a la residencia deberá formalizarse ante el jefe del
              servicio de clínica médica o en su defecto al jefe de residentes
              con treinta(30 días) de antelación a la fecha del cese.
            </p>
            <h6 className="mt-5">Artículo 9</h6>
            <p className="mb-3">
              Las funciones del residente tenderá a su disposición la consulta a
              sus superiores durante todo el tiempo, al igual de el staff de
              especialista interconsultores a los cuales podrá tener acceso
              siempre que sea necesario. El servicio de clínica médica cuenta
              con un servicio de internet ininterrumpido en el cual el residente
              podrá hacer consultas virtuales de temas académicos, en sala de
              residentes y office de 2º y 3º piso están habilitados equipos de
              computación para dichas actividades y además cuenta con ayudas de
              estudios (fotocopias,scanner), servicio de fax, telefonía fija y
              celular para contactarse con superiores. El residente tiene
              derecho a realizar curso superior de medicina interna que se
              desarrolla los martes en la sede de la asociación médica
              argentina, lo desarrolla a partir del 2º año y es auspiciado en su
              totalidad por la dirección del sanatorio mediante una beca de
              estudio. El residente recibe mensualmente una bonificación la cual
              es depositada en una cuenta de ahorros que el sanatorio le abre al
              residente para tal fin, deberá estar inscrito en el régimen de
              monotributo y presentar mensualmente recibo.
            </p>
            <p className="mb-3">
              El sanatorio cuenta con una póliza colectiva contra accidentes
              laborales que cubre al profesional ante cualquier riesgo que
              conlleve su práctica médica en el Sanatorio.
            </p>
            <p className="mb-3">
              Servicio de cafetería (ubicado en el 5º piso) en el cual el
              residente recibe el almuerzo diariamente y el residente de guardia
              recibirá la cena y desayuno.
            </p>

            <h6 className="mt-5">Artículo 10</h6>
            <p className="mb-3">
              Para llevar a cabo los objetivos y mantener un correcto y armónico
              funcionamiento de la residencia los profesionales residentes deben
              cumplir ineludiblemente las obligaciones que se consignan a
              continuación:
            </p>

            <ul className="list-unstyled">
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>
                  El médico residente desarrollará actividades desde las 8-17
                  horas de lunes a Viernes y Sábados de 8-13 hrs, sin perjuicio
                  de ello deberá cumplir íntegramente las tareas antes de
                  retirarse.
                </span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>
                  Cumplirá programa de residencia con dedicación exclusiva.
                </span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>
                  Cubrirá guardias de acuerdo a los requerimientos del servicio
                  de clínica médica, el residente de 1º año cubrirá 8-10
                  guardias mensuales y el de 2º año 8 guardias
                  mensuales(cronograma a cargo del jefe de residentes).
                </span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>
                  Confeccionará la historia clínica de los pacientes internados
                  en su guardia, incluyendo la solicitud de análisis y estudios
                  complementarios,el residente de 1º año tiene esta obligación
                  con la supervisión del residente superior, a su vez es
                  obligación del residente de 2º año elaborar las indicaciones
                  médicas de ese paciente e informar al paciente y/o familiares
                  de su condición clínica,salvo si hubiera casos excepcionales
                  al respecto.
                </span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>
                  Pasará revista a los enfermos internados en su guardia tantas
                  veces sea necesario, anotando fecha y hora,siempre con la
                  supervisión del residente superior y en cada caso siempre
                  anotará en la historia clínica las veces que fuese necesario
                  los hallazgos de la evolución del paciente y cualquier
                  novedad, al igual que describirá estudios y prácticas de
                  laboratorio, balances hídricos, vitales, neurológicos.
                </span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>
                  Asistirá a los pacientes de su sala y/o sector bajo la
                  supervisión del jefe de sala, jefe de servicio , jefe de
                  residente y residentes superiores.
                </span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>
                  Para las finalidades formativas y administrativas de la
                  residencia dependerá en orden creciente de Jefe de
                  residente,Jefe de Sala,Jefe de servicio,Comité de
                  docencia,Director del sanatorio.
                </span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>
                  Estará encargado el residente de actualizar a diario las
                  historias clínicas de los pacientes de su sector.
                </span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>
                  El residente de 2º año confeccionará diariamente las
                  indicaciones médicas y tipo de alimentación de los pacientes
                  internados, firmándolas al pie e indicando fecha y hora de
                  ejecución de la misma.
                </span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>
                  Presentará los pacientes al jefe de servicio,médicos
                  especialista interconsultores y jefe de residentes en el pase
                  de sala o en cualquier momento que se lo requiera, en forma
                  breve y completa,los exámenes ordenados y al día deberán ser
                  conocidos por los residentes y deberán estar siempre
                  disponibles para su evaluación.
                </span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>
                  Solicitará consentimiento informado y se anotará en la
                  historia clínica.
                </span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>
                  Deberá acompañar al médico especialista interconsultor en la
                  evaluación de pacientes siempre que fuese necesario,y
                  solicitará al especialista interconsultor el informe escrito.
                </span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>
                  Consultará obligatoriamente a su superior en toda
                  circunstancia que enfrente un problema diagnóstico o
                  terapéutico que supere sus conocimientos o experiencia.
                </span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>
                  Deberá conocer a todos los enfermos internados en el servicio
                  y las novedades de su evolución.
                </span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>
                  No podrá retirarse del sanatorio en el horario de trabajo sin
                  autorización del jefe de residentes,cuando una circunstancia
                  excepcional lo requiera podrá hacerlo solicitando previa
                  autorización.
                </span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>
                  Toda ausencia por motivos personales o enfermedad deberá ser
                  notificada a la brevedad al jefe de residentes y/o jefe de
                  servicio.
                </span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>
                  El jefe de residente tomará cuenta de toda ausencia
                  injustificada y de acuerdo a la jefatura de servicio tomará
                  las medidas que sean necesarias,el residente deberá presentar
                  constancia en caso de causa justificada dentro de las 24 hrs
                  de su ausencia.
                </span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>
                  Todo hecho que adquiera o pueda adquirir características
                  médico-legales y en los que intervenga el residente será
                  comunicado de inmediato al superior que corresponda de acuerdo
                  a la hora de su producción.
                </span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>
                  En lo posible supervisará la actividad del personal auxiliar
                  especialmente durante las guardias y además asesora cuando lo
                  considere necesario o le sea requerido.
                </span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>
                  Durante las guardias será supervisado por el residente
                  superior y en forma de disponibilidad por el jefe de residente
                  y/o jefe de servicio de clínica médica.
                </span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>
                  Dentro del plan docente y su formación realizará bajo la
                  supervisión del jefe de residentes, jefe de servicio, médico
                  de planta, especialista, o residente superior las prácticas
                  invasivas que correspondieren.
                </span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>
                  Gozará de las licencias, permisos y demás beneficios que
                  otorga el reglamento de residentes vigente a la fecha.
                </span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>
                  Tendrá rotaciones en servicio de Cardiología,Terapia Intensiva
                  y rotaciones optativas durante el programa de residencia de
                  Clínica Médica.
                </span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>
                  Mantendrá en todo momento la debida reserva que los asuntos
                  del servicio lo requieran de acuerdo a la índole de los temas
                  tratados.
                </span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>
                  Observar en el servicio y fuera de el una conducta decorosa y
                  digna,acorde a las tareas asignadas;deberá mantener un perfil
                  profesional y una perfecta imagen de pulcritud en cuanto al
                  aseo y vestimenta, deberá estar con ambo y/o guardapolvo
                  blanco encima de su ropa de vestir.
                </span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>
                  Deberá conservar las normas de bioseguridad universal, con
                  lavado de manos antes y después de atender un paciente y
                  durante las prácticas invasivas deberá tener estricto respeto
                  de las normas universales de asepsia y antisepsia.
                </span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>
                  Procederá con cortesía, diligencia y ecuanimidad en el trato a
                  los pacientes y familiares de este.
                </span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>
                  Mantendrá vínculos cordiales, demostrara espíritu de
                  colaboración,solidaridad y respeto para con los otros
                  residentes, superiores y resto del personal.
                </span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>
                  Declarar y mantener actualizado su domicilio,teléfono fijo o
                  celular, dirección electrónica ante el establecimiento.
                </span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>
                  Mantener en orden las instalaciones del office de Clínica
                  médica.
                </span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>
                  Residente de 2º año deberá asistir al curso superior de
                  medicina interna y participar en la elaboración de ateneos,
                  con la supervisión del jefe de residentes o jefe del servicio
                  y compilar información estadística para estudios programados
                  por el servicio.
                </span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>
                  Deberá el residente de 2º año supervisar permanentemente al
                  residente de 1º año en sus actividades y durante las guardias
                  nocturnas.
                </span>
              </li>
            </ul>
            <h6 className="mt-5">Artículo 11</h6>
            <p className="mb-3">
              Son causales para la aplicación de sanciones:
            </p>
            <ul className="list-unstyled">
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>Incumplimiento reiterado del horario.</span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>
                  Inasistencia injustificada discontinuas que no excedan 15 días
                  durante el periodo de residencia.
                </span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>
                  Falta de respeto a superiores, subordinados, patres,
                  pacientes, familiares.
                </span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>
                  Incumplimiento de las obligaciones establecidas en el Artículo
                  10.
                </span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>No cumplir con la beca de dedicación exclusiva.</span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>
                  Falta que perjudique material o éticamente al establecimiento.
                </span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>
                  Son causales de baja del régimen de residencia no haber
                  aprobado las evaluaciones para la promoción al año
                  inmediatamente superior.
                </span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>
                  Incumplimiento de las rotaciones preestablecidas tanto en la
                  asistencia como en la adquisición de conocimientos y/o
                  actividades docentes.
                </span>
              </li>
            </ul>
            <p className="mb-3">Las sanciones a publicar son:</p>
            <ul className="list-unstyled">
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>Llamado de atención personal en privado.</span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>Apercibimiento.</span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>Guardia extra.</span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>Suspensión.</span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>Baja al régimen de residencia.</span>
              </li>
            </ul>
            <p className="mb-3">
              Las sanciones serán aplicadas por el jefe de servicio de clínica
              médica y serán notificadas por escrito al residente quien
              previamente elevará su descargo correspondiente. El residente que
              inasista sin aviso o injustificadamente durante 3 (tres días)
              consecutivos a desarrollar sus tareas incurrirá en abandono de la
              residencia.
            </p>
            <h6 className="mt-5">Artículo 12</h6>
            <p className="mb-3">Tipos de sanciones:</p>
            <ul className="list-unstyled">
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>
                  La llegada tarde al pase de guardia de la mañana será
                  inicialmente sancionada con un llamado de atención privado y
                  si reitera será apercibido y en el tercer episodio se le
                  penalizarà con salida ese día a las 20 hrs ( si no està de
                  guardia ese día, siestà de guardia se le hará la penalización
                  en otro día ante de cumplirse la semana de la falta); si se
                  continúa reiterando el incumplimiento del horario se le
                  impondrà una guardia extra dentro de la semana a la falta
                </span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>
                  El abandono de la guardia, la no justificación de una
                  inasistencia se considerarà falta grave y se penaliza con una
                  guardia extra.
                </span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>
                  El incumplimiento de las obligaciones del Artìculo 10 sera
                  consideradas faltas graves y serán sancionados de acuerdo a la
                  consideración de la jefatura del servicio de clínica mèdica y
                  comité de docencia y dirección.
                </span>
              </li>
            </ul>

            <h6 className="mt-5">Artículo 13</h6>
            <p className="mb-3">Derechos de los Residentes:</p>

            <ul className="list-unstyled">
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>
                  Serán supervisados permanentemente por superiores en el
                  desarrollo de sus actividades Médicas.
                </span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>
                  Recibir la capacitación correspondiente en su entrenamiento.
                </span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>
                  Bonificaciòn mensual cuyo monto establecerá la dirección
                  Médica.
                </span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>
                  Servicio de alimentación almuerzo diario y cena+desayuno a los
                  residentes de guardia.
                </span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>
                  Contar con material bibliográfico de consulta permanente(la
                  Instituciòn cuenta con servicio de internet para consultas
                  bibliográficas permanentes) y medios de comunicación con los
                  superiores a través de telefonía fija, móvil, fax, internet.
                </span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>
                  Concurrir a jornadas académicas, congresos, cursos con la
                  autorización pertinente y siempre que ello no interfiera con
                  el desarrollo de las programaciones docentes y asistenciales.
                </span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>
                  Gozar de una licencia anual de quince (15) días corridos en
                  verano,estos podrán ser tomados de forma corrida en única vez
                  al año si la organización del servicio de clínica Médica lo
                  permite.
                </span>
              </li>
              <li className="mb-2 d-flex align-items-center">
                <img src={Check} alt="check icon" className="icon-align pr-2" />{" "}
                <span>
                  Gozar de licencias especiales matrimonio, maternidad,
                  paternidad, por razones de salud justificadas, fallecimiento
                  de familiar en primer grado (5 días corridos), enfermedad
                  profesional o accidente de trabajo( lo que correspondiere).
                </span>
              </li>
            </ul>
          </Col>
        </Row>
      </Col>
    </PageWrapper>
  );
};

export default Reglamento;
