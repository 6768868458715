import React from "react";

import Map from "../../assets/img/maplocation.svg";
import Phone from "../../assets/img/phone-icon.svg";

const PharmaciesItem = ({ pharmacy }) => (
  <div className="container__resultado-busqueda">
    <h6 className="resultado-busqueda__title">{pharmacy.name}</h6>
    <span className="d-flex resultado-busqueda__text">
      <div className="icon_resultado mr-2">
        <img src={Map} alt="map icon" className="w-100" />
      </div>
      {pharmacy.address}
    </span>
    <span className="d-flex resultado-busqueda__text">
      <div className="icon_resultado mr-2">
        <img src={Phone} alt="map icon" className="w-100" />
      </div>
      {pharmacy.phone}
    </span>
  </div>
);

export default PharmaciesItem;
