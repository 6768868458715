// COMMENT: CREATE THIS FILE
import React, { useEffect, useState } from "react";
import Select from "react-select";

import { connect } from "react-redux";
import { getPharmaciesName } from "../redux/actions/pharmaciesName";
import { customStyles } from "./styles/common";

const PharmaciesNameSelect = ({
  getPharmaciesName,
  data,
  loading,
  onSelect,
}) => {
  const [value, setValue] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      await getPharmaciesName();
    };

    fetchData();
  }, [getPharmaciesName]);

  return (
    <Select
      className="basic-single"
      classNamePrefix="custom-select"
      options={data}
      value={value}
      onChange={(value) => {
        setValue(value);
        onSelect(value.label);
      }}
      placeholder="Nombre de la farmacia"
      styles={customStyles}
      noOptionsMessage={() => "No se encontraron datos."}
      isLoading={loading}
      clearable={false}
    />
  );
};

const mapStateToProps = (state) => ({
  data: state.pharmaciesName.data,
  loading: state.pharmaciesName.loading,
});

export default connect(mapStateToProps, {
  getPharmaciesName,
})(PharmaciesNameSelect);
