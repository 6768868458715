import React from "react";
import Tags from "../components/Meta/Tags";
import Banner from "../components/Banner";
import MedicalCentersContent from "../components/MedicalCenters/Content";
import ImageBanner from "../assets/img/centros-medicos.jpg";

const MedicalCenters = () => (
  <>
   <Tags 
    title="Centros médicos" 
    description="Servicios de primer nivel con tecnología de punta en todos nuestros centros | infraestructura propia | atención inmediata" 
    keywords="Tecnología, infraestructura propia, atención inmediata, Diagnostico por imagens, Consultorios medicos, Centro médico, Centro de la mujer, Guardia, Clinica, Hospital, Laboratorio" 
    canonical="/centros-medicos" />
    <Banner title="Centros Médicos" image={ImageBanner} height="cm" />
    <MedicalCentersContent />
  </>
);

export default MedicalCenters;
