import { createStore, applyMiddleware } from "redux";
import ReduxThunk from "redux-thunk";
import { createLogger } from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from "redux-persist";

import storageSession from "redux-persist/lib/storage/session";

import RootReducer from "./reducers";

const loggerMiddleware = createLogger({
  level: "info",
  collapsed: true
});

const authPersistConfig = {
  key: "auth",
  storage: storageSession,
  whitelist: ["auth"]
};

const persistedReducer = persistReducer(authPersistConfig, RootReducer);

const middlewares = [ReduxThunk, loggerMiddleware];

const devTools =
  process.env.NODE_ENV === "production"
    ? applyMiddleware(...middlewares)
    : composeWithDevTools(applyMiddleware(...middlewares));

export const store = createStore(persistedReducer, devTools);
export const persistor = persistStore(store);
