import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { FormGroup, FormFeedback, Input } from "reactstrap";

import { connect } from "react-redux";
import { getFormTypes, resetFormTypes } from "../redux/actions/form_types";
import { getFormTypeByWord } from "../lib/getFormTypeBySlug";
import { addContactForm } from "../redux/actions/common";

import { Formik, Form } from "formik";
import * as Yup from "yup";

import SubmitBtn from "./SubmitBtn";
import FormLoading from "./FormLoading";

const SolicitudInfoForm = ({
  formTypes,
  loading,
  getFormTypes,
  addContactForm,
  user,
  history,
  resetFormTypes
}) => {
  const [formTypeId, setFormTypeId] = useState(null);

  useEffect(() => {
    const fetchFormTypes = async () => await getFormTypes(true);

    fetchFormTypes();
  }, [getFormTypes]);

  useEffect(() => {
    if (formTypes.length > 0) {
      setFormTypeId(getFormTypeByWord("solicitud", formTypes));
    }
  }, [formTypes, setFormTypeId]);

  useEffect(() => {
    return () => resetFormTypes();
  }, [resetFormTypes]);

  if (loading) {
    return <FormLoading textarea />;
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        name: user ? user.full_name : "",
        title: "Solicitud de Información.",
        message: "",
        email: user ? user.email : "",
        phone: user ? user.phone : "",
        contact_form_type_id: formTypeId || ""
      }}
      validationSchema={Yup.object().shape({
        message: Yup.string().required("Requerido.")
      })}
      onSubmit={async (values, { setSubmitting }) => {
        const response = await addContactForm(values);
        setSubmitting(false);

        if (response === 200) {
          history.push("/panel/tramites");
        }
      }}
    >
      {({
        touched,
        errors,
        isSubmitting,
        handleBlur,
        values,
        handleChange
      }) => (
        <Form className="mt-4">
          <fieldset disabled={isSubmitting} aria-busy={isSubmitting}>
            <FormGroup>
              <Input
                type="textarea"
                name="message"
                className={`form-control__round ${errors.message &&
                  touched.message &&
                  "is-invalid"}`}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.message}
                placeholder="Deseo recibir información sobre..."
              />
              {errors.message && touched.message && (
                <FormFeedback className="d-block">
                  {errors.message}
                </FormFeedback>
              )}
              <small className="form-text text-muted">
                Por favor especifique los detalles de su solicitud, su número de
                afiliado, y su nombre y apellido, para generar una más rápida
                respuesta.
              </small>
            </FormGroup>

            <SubmitBtn
              color="orange"
              className="mt-4"
              text="Enviar"
              loading={isSubmitting}
            />
          </fieldset>
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = state => ({
  formTypes: state.formTypes.formTypes,
  loading: state.formTypes.loading,
  user: state.auth.user
});

export default withRouter(
  connect(mapStateToProps, {
    getFormTypes,
    addContactForm,
    resetFormTypes
  })(SolicitudInfoForm)
);
