import {
  LOADING_MEDICAL_ENTITY,
  GET_MEDICAL_ENTITY_SUCCESS,
  GET_MEDICAL_ENTITY_FAILURE,
  SET_MEDICAL_ENTITY_PAGE,
  RESET_MEDICAL_ENTITY,
  SET_MEDICAL_SERVICE_ID,
  SET_MEDICAL_SERVICE_ID_NAME,
  SET_MEDICAL_SPECIALTY_ID,
  SET_LOCATIONS_ENTITY_ID,
  SET_AREA_ENTITY_ID,
  SET_PROVINCE_ENTITY_ID,
  // COMMENT: ADD THIS LINE
  SET_MEDICAL_ENTITY_NAME,
} from "../actions/types/medicalEntity";

const initialState = {
  medicalEntity: [],
  loading: false,
  markers: [],
  links: {
    first: null,
    last: null,
    next: null,
    prev: null
  },
  meta: {
    currentPage: 1,
    from: null,
    to: null,
    total: null
  },
  filters: {
    page: 1,
    medical_service_id: null,
    medical_service_id_name: null,
    medical_specialty_id: null,
    location_id: null,
    area_id: null,
    province_id: null,
    // COMMENT: ADD THIS LINE
    medical_service_name: null,
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING_MEDICAL_ENTITY: {
      return {
        ...state,
        loading: true
      };
    }

    case GET_MEDICAL_ENTITY_SUCCESS: {
      return {
        ...state,
        loading: false,
        medicalEntity: [...action.payload.medicalEntity],
        markers: [...action.payload.markers],
        links: {
          ...state.links,
          first: action.payload.links.first,
          last: action.payload.links.last,
          next: action.payload.links.next,
          prev: action.payload.links.prev
        },
        meta: {
          ...state.meta,
          currentPage: action.payload.meta.currentPage,
          from: action.payload.meta.from,
          to: action.payload.meta.to,
          total: action.payload.meta.total
        }
      };
    }

    case GET_MEDICAL_ENTITY_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }

    case SET_MEDICAL_ENTITY_PAGE: {
      return {
        ...state,
        loading: false,
        meta: {
          ...state.meta,
          currentPage: action.payload.page
        },
        filters: {
          ...state.filters,
          page: action.payload.page
        }
      };
    }

    case SET_MEDICAL_SERVICE_ID: {
      return {
        ...state,
        loading: false,
        filters: {
          ...state.filters,
          medical_service_id: action.payload.value,
          page: 1
        }
      };
    }


    case SET_MEDICAL_SERVICE_ID_NAME: {
      return {
        ...state,
        loading: false,
        filters: {
          ...state.filters,
          medical_service_id_name: action.payload.value,
          page: 1
        }
      };
    }

    case SET_MEDICAL_SPECIALTY_ID: {
      return {
        ...state,
        loading: false,
        filters: {
          ...state.filters,
          medical_specialty_id: action.payload.value,
          page: 1
        }
      };
    }

    case SET_LOCATIONS_ENTITY_ID: {
      return {
        ...state,
        filters: {
          ...state.filters,
          location_id: action.payload.value,
          page: 1
        }
      };
    }

    case SET_PROVINCE_ENTITY_ID: {
      return {
        ...state,
        filters: {
          ...state.filters,
          province_id: action.payload.value,
          page: 1
        }
      };
    }

    case SET_AREA_ENTITY_ID: {
      return {
        ...state,
        filters: {
          ...state.filters,
          area_id: action.payload.value,
          page: 1
        }
      };
    }

    case RESET_MEDICAL_ENTITY: {
      return {
        ...state,
        ...initialState
      };
    }
    // COMMENT: START CODE 

    case SET_MEDICAL_ENTITY_NAME: {
      return {
        ...state,
        filters: {
          ...state.filters,
          medical_service_name: action.payload.value,
          page: 1,
        },
      };
    }
  // COMMENT: END CODE 
    default:
      return state;
  }
};
