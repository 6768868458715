import React from "react";
import Tags from "../components/Meta/Tags";
import Banner from "../components/Banner";
import Content from "../components/Reglamento/Content";

import ImageBanner from "../assets/img/reglamento.jpg";

const Reglamento = () => (
  <>
   <Tags 
    title="Reglamento" 
    description="Todo lo que tenes que saber sobre tu cobertura." 
    keywords="Reglamento, Reglas, Cobertura, Requisitos para el ingreso, Preexistencias, Exclusiones" 
    canonical="/" />
    <Banner title="Reglamento" image={ImageBanner} />
    <Content />
  </>
);

export default Reglamento;
