import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Row, Col, FormGroup, FormFeedback, Input } from "reactstrap";

import { connect } from "react-redux";
import { getFormTypes, resetFormTypes } from "../../redux/actions/form_types";
import { getFormTypeByWord } from "../../lib/getFormTypeBySlug";
import { addContactForm } from "../../redux/actions/common";

import { Formik, Form } from "formik";
import * as Yup from "yup";

import PanelTitle from "../PanelTitle";
import Icon from "../../assets/img/autorizaciones.svg";
import AddFile from "../../assets/img/agregar_archivo.svg";
import SubmitBtn from "../SubmitBtn";
import FormLoading from "../FormLoading";


const Autorizaciones = ({
  formTypes,
  loading,
  getFormTypes,
  addContactForm,
  user,
  resetFormTypes
}) => {
  const [formTypeId, setFormTypeId] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [fileName02, setFileName02] = useState(null);
  const [fileName03, setFileName03] = useState(null);
  const [fileName04, setFileName04] = useState(null);
  const [isVisibleFrmGrp02, setDisplayFrmGrp02] = useState(false);
  const [isVisibleFrmGrp03, setDisplayFrmGrp03] = useState(false);
  const [isVisibleFrmGrp04, setDisplayFrmGrp04] = useState(false);

  useEffect(() => {
    const fetchFormTypes = async () => await getFormTypes(true);

    fetchFormTypes();
  }, [getFormTypes]);

  useEffect(() => {
    if (formTypes.length > 0) {
      setFormTypeId(getFormTypeByWord("autorizaciones", formTypes));
    }
  }, [formTypes]);

  useEffect(() => {
    return () => resetFormTypes();
  }, [resetFormTypes]);

  return (
    <>
      <PanelTitle icon={Icon}>Autorizaciones: Estudios y prácticas</PanelTitle>
      <Row className="mt-3 mt-sm-5">
        <Col xs={12}>
          <h6 className="mb-3">
            Por favor completar los datos y adjuntar orden médica.<br></br>
            La orden será procesada dentro de las 72 horas hábiles. 
          </h6>
        </Col>
        <Col xs={12} sm={6}>
          {loading ? (
            <FormLoading />
          ) : (
            <Formik
              enableReinitialize={true}
              initialValues={{
                name: user.full_name || "",
                title: "Autorizaciones",
                message: "",
                email: "",
                attachment: "",
                place01: "",
                attachment02: "",
                place02: "",
                attachment03: "",
                place03: "",
                attachment04: "",
                place04: "",
                contact_form_type_id: formTypeId
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email()
                  .typeError("Debe ser un email valido.")
                  .required("Completar datos."),
                //message: Yup.string().required("Completar datos."),
                attachment: Yup.string().required("Adjuntar orden médica."),
                place01: Yup.string().required("Debe especificar el lugar de realización del estudio.")
              })}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                const data = new FormData();

                data.append("attachment", values.attachment);
                data.append("attachment02", values.attachment02);
                data.append("attachment03", values.attachment03);
                data.append("attachment04", values.attachment04);
                data.append("place01", values.place01);
                data.append("place02", values.place02);
                data.append("place03", values.place03);
                data.append("place04", values.place04);
                data.append("name", values.name);
                data.append("title", values.title);
                data.append("message", values.message);
                data.append("email", values.email);
                data.append(
                  "contact_form_type_id",
                  values.contact_form_type_id
                );

                const response = await addContactForm(data);

                setSubmitting(false);

                if (response === 200) {
                  resetForm({
                    name: user.full_name || "",
                    title: "Autorizaciones",
                    message: "",
                    email: "",
                    attachment: "",
                    place01: "",
                    attachment02: "",
                    place02: "",
                    attachment03: "",
                    place03: "",
                    attachment04: "",
                    place04: "",
                    contact_form_type_id: formTypeId
                  });

                  setFileName(null);
                  setFileName02(null);
                  setFileName03(null);
                  setFileName04(null);
                }
              }}
            >
              {({
                touched,
                errors,
                isSubmitting,
                handleBlur,
                values,
                handleChange,
                setFieldValue
              }) => (
                <Form className="mt-4">
                  <fieldset disabled={isSubmitting} aria-busy={isSubmitting}>
                    <FormGroup>
                      <Input
                        type="text"
                        name="email"
                        className={`form-control__round ${errors.email &&
                          touched.email &&
                          "is-invalid"}`}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                        autoComplete="name"
                        placeholder="Email"
                      />
                      {errors.email && touched.email && (
                        <FormFeedback className="d-block">
                          {errors.email}
                        </FormFeedback>
                      )}
                    </FormGroup>

                    <FormGroup>
                      <img src={AddFile} className="pr-1" alt="add icon" />
                      <label htmlFor="upload-photo" className="add-file-input">
                        Añadir archivo
                      </label>
                      <input
                        className="d-block w-25"
                        type="file"
                        name="attachment"
                        id="upload-photo"
                        onChange={e => {
                          e.preventDefault();
                          let file = e.target.files[0];

                          if (!file) {
                            setDisplayFrmGrp02(false);
                            return;
                          }

                          setDisplayFrmGrp02(true);
                          setFieldValue("attachment", file);
                          setFileName(file.name);
                        }}
                      />
                      {fileName && (
                        <h6 className="d-block">{fileName}</h6>
                      )}

                      {errors.attachment && touched.attachment && (
                        <FormFeedback className="d-block my-2">
                          {errors.attachment}
                        </FormFeedback>
                      )}

                      <small className="d-block">
                        (Extensiones permitidas: PDF, JPG).
                      </small>
                    </FormGroup>

                    <FormGroup>
                      <Input
                        type="text"
                        name="place01"
                        className={`form-control__round ${errors.place01 &&
                          touched.place01 &&
                          "is-invalid"}`}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.place01}
                        autoComplete="name"
                        placeholder="Lugar de realización del estudio"
                      />
                      {errors.place01 && touched.place01 && (
                        <FormFeedback className="d-block">
                          {errors.place01}
                        </FormFeedback>
                      )}
                    </FormGroup>

                    <FormGroup className={`frmGrp02 ${isVisibleFrmGrp02 ? "" : "hidden"}`}>
                      <img src={AddFile} className="pr-1" alt="add icon" />
                      <label htmlFor="upload-photo02" className="add-file-input">
                        Añadir archivo 02
                      </label>
                      <input
                        className="d-block w-25 upload-photo"
                        type="file"
                        name="attachment02"
                        id="upload-photo02"
                        onChange={e => {
                          e.preventDefault();
                          let file02 = e.target.files[0];

                          if (!file02) {
                            setDisplayFrmGrp03(false);
                            return;
                          }

                          setDisplayFrmGrp03(true);
                          setFieldValue("attachment02", file02);
                          setFileName02(file02.name);
                        }}
                      />
                      {fileName02 && (
                        <h6 className="d-block">{fileName02}</h6>
                      )}

                      {errors.attachment02 && touched.attachment02 && (
                        <FormFeedback className="d-block my-2">
                          {errors.attachment02}
                        </FormFeedback>
                      )}

                      <small className="d-block">
                        (Extensiones permitidas: PDF, JPG).
                      </small>
                    </FormGroup>

                    <FormGroup className={`frmGrp02 ${isVisibleFrmGrp02 ? "" : "hidden"}`}>
                      <Input
                        type="text"
                        name="place02"
                        className={`form-control__round ${errors.place02 &&
                          touched.place02 &&
                          "is-invalid"}`}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.place02}
                        autoComplete="name"
                        placeholder="Lugar de realización del estudio 02"
                      />
                      {errors.place02 && touched.place02 && (
                        <FormFeedback className="d-block">
                          {errors.place02}
                        </FormFeedback>
                      )}
                    </FormGroup>

                    <FormGroup className={`frmGrp03 ${isVisibleFrmGrp03 ? "" : "hidden"}`}>
                      <img src={AddFile} className="pr-1" alt="add icon" />
                      <label htmlFor="upload-photo03" className="add-file-input">
                        Añadir archivo 03
                      </label>
                      <input
                        className="d-block w-25 upload-photo"
                        type="file"
                        name="attachment03"
                        id="upload-photo03"
                        onChange={e => {
                          e.preventDefault();
                          let file03 = e.target.files[0];

                          if (!file03) {
                            setDisplayFrmGrp04(false);
                            return;
                          }

                          setDisplayFrmGrp04(true);
                          setFieldValue("attachment03", file03);
                          setFileName03(file03.name);
                        }}
                      />
                      {fileName03 && (
                        <h6 className="d-block">{fileName03}</h6>
                      )}

                      {errors.attachment03 && touched.attachment03 && (
                        <FormFeedback className="d-block my-2">
                          {errors.attachment03}
                        </FormFeedback>
                      )}

                      <small className="d-block">
                        (Extensiones permitidas: PDF, JPG).
                      </small>
                    </FormGroup>

                    <FormGroup className={`frmGrp03 ${isVisibleFrmGrp03 ? "" : "hidden"}`}>
                      <Input
                        type="text"
                        name="place03"
                        className={`form-control__round ${errors.place03 &&
                          touched.place03 &&
                          "is-invalid"}`}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.place03}
                        autoComplete="name"
                        placeholder="Lugar de realización del estudio 03"
                      />
                      {errors.place03 && touched.place03 && (
                        <FormFeedback className="d-block">
                          {errors.place03}
                        </FormFeedback>
                      )}
                    </FormGroup>

                    <FormGroup className={`frmGrp04 ${isVisibleFrmGrp04 ? "" : "hidden"}`}>
                      <img src={AddFile} className="pr-1" alt="add icon" />
                      <label htmlFor="upload-photo04" className="add-file-input">
                        Añadir archivo 04
                      </label>
                      <input
                        className="d-block w-25 upload-photo"
                        type="file"
                        name="attachment04"
                        id="upload-photo04"
                        onChange={e => {
                          e.preventDefault();
                          let file04 = e.target.files[0];

                          if (!file04) {
                            return;
                          }

                          setFieldValue("attachment04", file04);
                          setFileName04(file04.name);
                        }}
                      />
                      {fileName04 && (
                        <h6 className="d-block">{fileName04}</h6>
                      )}

                      {errors.attachment04 && touched.attachment04 && (
                        <FormFeedback className="d-block my-2">
                          {errors.attachment04}
                        </FormFeedback>
                      )}

                      <small className="d-block">
                        (Extensiones permitidas: PDF, JPG).
                      </small>
                    </FormGroup>

                    <FormGroup className={`frmGrp04 ${isVisibleFrmGrp04 ? "" : "hidden"}`}>
                      <Input
                        type="text"
                        name="place04"
                        className={`form-control__round ${errors.place03 &&
                          touched.place04 &&
                          "is-invalid"}`}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.place04}
                        autoComplete="name"
                        placeholder="Lugar de realización del estudio 04"
                      />
                      {errors.place04 && touched.place04 && (
                        <FormFeedback className="d-block">
                          {errors.place04}
                        </FormFeedback>
                      )}
                    </FormGroup>

                    {/* Comentarios opcionales */}

                    <FormGroup>
                      <Input
                        type="textarea"
                        name="message"
                        className={`form-control__round ${errors.message &&
                          touched.message &&
                          "is-invalid"}`}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.message}
                        placeholder="Comentarios, nombre, fecha de prestación, teléfono de contacto, etc. (opcional)"
                      />
                      {errors.message && touched.message && (
                        <FormFeedback className="d-block">
                          {errors.message}
                        </FormFeedback>
                      )}
                    </FormGroup>

                    <SubmitBtn
                      color="orange"
                      className="mt-4"
                      text="Enviar"
                      loading={isSubmitting}
                    />
                  </fieldset>
                </Form>
              )}
            </Formik>
          )}
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = state => ({
  formTypes: state.formTypes.formTypes,
  loading: state.formTypes.loading,
  user: state.auth.user
});

export default withRouter(
  connect(mapStateToProps, {
    addContactForm,
    getFormTypes,
    resetFormTypes
  })(Autorizaciones)
);
