import React, { useEffect, useRef, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { Col, CardBody, CardHeader } from "reactstrap";
import { Accordion, Button } from "react-bootstrap";
import queryString from "query-string";

import PageWrapper from "../PageWrapper";
import FaqsTitle from "./FaqsTitle";

import CaretDown from "../../assets/img/caret-down-menu.svg";
import Map from "../../assets/img/maplocation.svg";
import Phone from "../../assets/img/phone-icon.svg";
import Mail from "../../assets/img/mail.svg";
import TurnosPDF from "../../assets/img/turnos_web.pdf";
import Check from "../../assets/img/check-circle-regular.svg";

const Content = ({ location }) => {
  const params = queryString.parse(location.search);
  const [opened, setOpen] = useState(params.ff === "true" ? true : false);
  const element = useRef();

  useEffect(() => {
    setTimeout(() => {
      if (params.ff === "true") {
        document.documentElement.scrollTop =
          element.current.getBoundingClientRect().top + window.scrollY - 150;
        setOpen(true);
      }
    }, 300);
  }, [params.ff]);

  useEffect(() => {
    return () => setOpen(false);
  }, []);

  return (
    <PageWrapper>
      <Col xs="12">
        <FaqsTitle>Asistencia al Viajero</FaqsTitle>
        <Accordion>
          <div>
            <CardHeader className="faqs__card-header">
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey="0"
                className="faqs__titles w-100"
              >
                ¿Los planes disponen de cobertura de asistencia en viaje?
                <img src={CaretDown} alt="caret down" className="pl-2" />
              </Accordion.Toggle>
            </CardHeader>
            <Accordion.Collapse eventKey="0">
              <CardBody className="body-wrapper">
                <p>
                  La cobertura de asistencia en viaje se vincula con el plan
                  elegido. Actualmente el plan A-10 Plus cuenta con asistencia
                  al viajero por{" "}
                  <span className="semi-bold-font">Universal Assistance</span>{" "}
                  mientras que el plan C-60 Plus ofrece importantes descuentos
                  en una variedad de paquetes. Para más información sobre los
                  paquetes que ofrece Universal Assistance dirigirse {" "}
                  <a
                    className="semi-bold-font links-inner-faqs"
                    href="https://www.universal-assistance.com/ar-es/affinity-partners?utm_source=santa_salud_ar_affinity&utm_medium=referral&utm_campaign=lp_santa_salud_arg"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    aquí
                  </a>
                  .
                </p>
              </CardBody>
            </Accordion.Collapse>
          </div>

          <div>
            <CardHeader className="faqs__card-header">
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey="1"
                className="faqs__titles w-100"
              >
                ¿Es necesario activar el servicio o hacer algún llamado antes de
                viajar?
                <img src={CaretDown} alt="caret down" className="pl-2" />
              </Accordion.Toggle>
            </CardHeader>
            <Accordion.Collapse eventKey="1">
              <CardBody className="body-wrapper">
                <p>
                  Para hacer uso del servicio, antes de viajar, aconsejamos que 
                  los socios se comuniquen con la línea exclusiva de 
                  Universal Assistance para afiliados de Alta Salud: 
                  0800-222-1939 / 0800-222-8565 y que se descarguen la APP para visualizar todos los servicios.
                </p>
              </CardBody>
            </Accordion.Collapse>
          </div>

          <div>
            <CardHeader className="faqs__card-header">
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey="2"
                className="faqs__titles w-100"
              >
                ¿Cómo accedo a los servicios de Universal Assistance?
                <img src={CaretDown} alt="caret down" className="pl-2" />
              </Accordion.Toggle>
            </CardHeader>
            <Accordion.Collapse eventKey="2">
              <CardBody className="body-wrapper">
                <p>
                  En caso de requerir el servicio, deberá comunicarse desde el
                  lugar donde se encuentre telefónicamente. Para consultar los
                  teléfonos, por favor ingrese a{" "}
                  <a
                    className="semi-bold-font links-inner-faqs"
                    href="https://www.universal-assistance.com/ar-es/asistencia.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.universal-assistance.com
                  </a>
                  .
                </p>
              </CardBody>
            </Accordion.Collapse>
          </div>

          <div>
            <CardHeader className="faqs__card-header">
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey="3"
                className="faqs__titles w-100"
              >
                Cómo usar la APP de Universal Assistance
                <img src={CaretDown} alt="caret down" className="pl-2" />
              </Accordion.Toggle>
            </CardHeader>

            <Accordion.Collapse eventKey="3">
              <CardBody className="body-wrapper">
                <ul className="list-unstyled">
                  <li className="mb-2 d-flex align-items-center">
                    <img src={Check} alt="check icon" className="icon-align pr-2" />
                    <span>
                      En el menú de inicio ir a Mi Cobertura
                    </span>
                  </li>

                  <li className="mb-2 d-flex align-items-center">
                    <img src={Check} alt="check icon" className="icon-align pr-2" />
                    <span>
                      Seleccionar tipo de documento
                    </span>
                  </li>

                  <li className="mb-2 d-flex align-items-center">
                    <img src={Check} alt="check icon" className="icon-align pr-2" />
                    <span>
                      Poner el número del documento seleccionado previamente
                    </span>
                  </li>

                  <li className="mb-2 d-flex align-items-center">
                    <img src={Check} alt="check icon" className="icon-align pr-2" />
                    <span>
                      Presionar acceder
                    </span>
                  </li>

                  <li className="mb-2 d-flex align-items-center">
                    <img src={Check} alt="check icon" className="icon-align pr-2" />
                    <span>
                      Ir a mi cobertura
                    </span>
                  </li>

                </ul>

                <p>
                  Este proceso debe realizarse para cada uno de los asociados que 
                  deseen obtener esta constancia, por lo que deberá cerrar la sesión e 
                  iniciarla con el otro D.N.I. solicitante.
                </p>

                <p>
                  Tenga en cuenta que estos procedimientos son obligatorios para que 
                  pueda acceder a la cobertura médica en tránsito. 
                  No se admiten reintegros por prestaciones y/o gastos médicos sanatoriales 
                  en ningún caso en que no se cumpla con el procedimiento previo 
                  de comunicación a Universal Assistance y/o no se sigan las directivas que ellos indican.
                </p>
                
              </CardBody>
            </Accordion.Collapse>
            
          </div>

        </Accordion>

        <FaqsTitle>Asociarme</FaqsTitle>
        <Accordion>
          <div>
            <CardHeader className="faqs__card-header">
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey="0"
                className="faqs__titles w-100"
              >
                ¿Cómo me asocio?
                <img src={CaretDown} alt="caret down" className="pl-2" />
              </Accordion.Toggle>
            </CardHeader>
            <Accordion.Collapse eventKey="0">
              <CardBody className="body-wrapper">
                <p>Usted podrá asociarse de las siguientes maneras:</p>
                <ul className="list-unstyled">
                  <li className="mb-2 d-flex align-items-center">
                    <img
                      src={Phone}
                      alt="Phone icon"
                      className="icon-align pr-2"
                    />{" "}
                    <span>
                      Llamando al <a href="tel:08003332582">0800-333-2582.</a>
                    </span>
                  </li>
                  <li className="mb-2 d-flex align-items-center">
                    <img src={Map} alt="Map icon" className="icon-align pr-2" />{" "}
                    <span>Av. Gaona 3519/27.</span>
                  </li>
                  <li className="mb-2 d-flex align-items-center">
                    <img
                      src={Mail}
                      alt="mail icon"
                      className="icon-align pr-2"
                    />{" "}
                    <span>
                      Via mail a{" "}
                      <a
                        className="links-inner-faqs"
                        href="mailto: comercial@altasalud.com.ar"
                      >
                        comercial@altasalud.com.ar
                      </a>
                    </span>
                  </li>
                </ul>

                <p className="pt-4">
                  También pueden visitar nuestra otra sucursal:
                </p>

                <ul className="list-unstyled">
                  <li className="mb-2 d-flex align-items-center">
                    <img
                      src={Phone}
                      alt="phone icon"
                      className="icon-align pr-2"
                    />{" "}
                    <span>
                      Llamando al <a href="tel:08109992582">0810-999-2582</a> o
                      por Whatsapp al <a href="tel:1140628176">11-4062-8176</a>.
                    </span>
                  </li>
                  <li className="mb-2 d-flex align-items-center">
                    <img src={Map} alt="map icon" className="icon-align pr-2" />{" "}
                    <span>Av. Carabobo 174.</span>
                  </li>
                  <li className="mb-2 d-flex align-items-center">
                    <img
                      src={Mail}
                      alt="mail icon"
                      className="icon-align pr-2"
                    />{" "}
                    <span>
                      Via mail a{" "}
                      <a
                        className="links-inner-faqs"
                        href="mailto: crodriguez@gerensal.com.ar"
                      >
                        crodriguez@gerensal.com.ar
                      </a>
                    </span>
                  </li>
                </ul>
              </CardBody>
            </Accordion.Collapse>
          </div>
        </Accordion>

        <FaqsTitle>Autorizaciones</FaqsTitle>
        <Accordion>
          <div>
            <CardHeader className="faqs__card-header">
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey="0"
                className="faqs__titles w-100"
              >
                ¿Cómo autorizo una orden médica?
                <img src={CaretDown} alt="caret down" className="pl-2" />
              </Accordion.Toggle>
            </CardHeader>
            <Accordion.Collapse eventKey="0">
              <CardBody className="body-wrapper">
                <p>
                  Las órdenes médicas se autorizan de las siguientes maneras:
                </p>
                <ul className="list-unstyled">
                  <li className="mb-2 d-flex align-items-center">
                    <img src={Map} alt="map icon" className="icon-align pr-2" />{" "}
                    <span>
                      Personalmente en Av. Gaona 3519/27, en el sector de
                      autorizaciones.
                    </span>
                  </li>
                  <li className="mb-2 d-flex align-items-center">
                    <img
                      src={Mail}
                      alt="mail icon"
                      className="icon-align pr-2"
                    />{" "}
                    <span>
                      Vía mail a{" "}
                      <a
                        className="links-inner-faqs"
                        href="mailto: autorizacionesgaona@altasalud.com.ar"
                      >
                        autorizacionesgaona@altasalud.com.ar
                      </a>
                    </span>
                  </li>
                  <li className="mb-2 d-flex align-items-center">
                    <img
                      src={Check}
                      alt="check icon"
                      className="icon-align pr-2"
                    />{" "}
                    <span>Nuestro sitio web ingresando a su perfil.</span>
                  </li>
                </ul>
              </CardBody>
            </Accordion.Collapse>
          </div>

          <div>
            <CardHeader className="faqs__card-header">
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey="1"
                className="faqs__titles w-100"
              >
                ¿Cómo es el acceso a la atención?
                <img src={CaretDown} alt="caret down" className="pl-2" />
              </Accordion.Toggle>
            </CardHeader>
            <Accordion.Collapse eventKey="1">
              <CardBody className="body-wrapper">
                <p>
                  El acceso al servicio es con credencial, DNI y último recibo
                  de pago con todos los prestadores que figuren en cartilla
                  médica.
                </p>
              </CardBody>
            </Accordion.Collapse>
          </div>

          <div>
            <CardHeader className="faqs__card-header">
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey="2"
                className="faqs__titles w-100"
              >
                ¿Cómo afilio a un recién nacido?
                <img src={CaretDown} alt="caret down" className="pl-2" />
              </Accordion.Toggle>
            </CardHeader>
            <Accordion.Collapse eventKey="2">
              <CardBody className="body-wrapper">
                <p>
                  Para afiliar a su bebé a Alta Salud debe presentar la
                  siguiente documentación:
                </p>
                <ul className="list-unstyled">
                  <li className="mb-2 d-flex align-items-center">
                    <img
                      src={Check}
                      alt="check icon"
                      className="icon-align pr-2"
                    />{" "}
                    <span>Epicrisis del alta del sanatorio donde nació.</span>
                  </li>
                  <li className="mb-2 d-flex align-items-center">
                    <img
                      src={Check}
                      alt="check icon"
                      className="icon-align pr-2"
                    />{" "}
                    <span>Partida de nacimiento.</span>
                  </li>
                  <li className="mb-2 d-flex align-items-center">
                    <img
                      src={Check}
                      alt="check icon"
                      className="icon-align pr-2"
                    />{" "}
                    <span>DNI.</span>
                  </li>
                </ul>

                <p>
                  Esta documentación original, deberá ser presentada
                  personalmente en Av. Gaona 3519/27.
                </p>
              </CardBody>
            </Accordion.Collapse>
          </div>
        </Accordion>

        <FaqsTitle>Centros Médicos Propios</FaqsTitle>
        <Accordion>
          <div>
            <CardHeader className="faqs__card-header">
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey="0"
                className="faqs__titles w-100"
              >
                ¿Cuáles son los centros medicos de propios?
                <img src={CaretDown} alt="caret down" className="pl-2" />
              </Accordion.Toggle>
            </CardHeader>
            <Accordion.Collapse eventKey="0">
              <CardBody className="body-wrapper">
                <ul className="list-unstyled">
                  <li className="mb-2 d-flex align-items-center">
                    <img
                      src={Check}
                      alt="check icon"
                      className="icon-align pr-2"
                    />
                    <span>
                      Clínica Alta Salud ubicada en Av. Nazca 1169, el teléfono
                      es <a href="tel:45885800">4588-5800</a>.
                    </span>
                  </li>
                  <li className="mb-2 d-flex align-items-center">
                    <img
                      src={Check}
                      alt="check icon"
                      className="icon-align pr-2"
                    />
                    <span>
                      Centro Médico Alta Salud en Av. Gaona 3519/27, el teléfono
                      es <a href="tel:45885900">4588-5900</a>.
                    </span>
                  </li>
                </ul>
              </CardBody>
            </Accordion.Collapse>
          </div>

          <div>
            <CardHeader className="faqs__card-header">
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey="1"
                className="faqs__titles w-100"
              >
                ¿Cómo sacar turnos online en el Centro Médico Alta Salud?
                <img src={CaretDown} alt="caret down" className="pl-2" />
              </Accordion.Toggle>
            </CardHeader>
            <Accordion.Collapse eventKey="1">
              <CardBody className="body-wrapper">
                <p>Puede sacar un turno a través de:</p>
                <ul className="list-unstyled">
                  <li className="mb-2 d-flex align-items-center">
                    <img
                      src={Check}
                      alt="check icon"
                      className="icon-align pr-2"
                    />{" "}
                    <span>
                      Sitio web{" "}
                      <a
                        href="http://www.altasalud.com.ar/"
                        target="_blank"
                        rel="nofollow noopener noreferrer"
                        className="links-inner-faqs"
                      >
                        www.altasalud.com.ar
                      </a>
                    </span>
                  </li>
                  <li className="mb-2 d-flex align-items-center">
                    <img
                      src={Phone}
                      alt="phone icon"
                      className="icon-align pr-2"
                    />{" "}
                    <span>
                      Llamando por telefono al:{" "}
                      <a href="tel:45885900">4588-5900.</a>
                    </span>
                  </li>
                  <li className="mb-2 d-flex align-items-center">
                    <img
                      src={Mail}
                      alt="Mail icon"
                      className="icon-align pr-2"
                    />{" "}
                    <span>
                      Vía mail a{" "}
                      <a
                        className="links-inner-faqs"
                        href="mailto: turnosgaona@altasalud.com.ar"
                      >
                        turnosgaona@altasalud.com.ar
                      </a>
                    </span>
                  </li>
                </ul>

                <p>
                  Acceda a nuestro instructivo para aprender a navegatar
                  nuestros sistema de turnos online para nuestro Centro Médico
                  Alta Salud en Av Gaona 3519/27.
                  <br />
                  <br />
                  <a
                    className="links-inner-faqs"
                    href={TurnosPDF}
                    download="Turnos Web"
                  >
                    Descargar instructivo
                  </a>
                </p>
              </CardBody>
            </Accordion.Collapse>
          </div>
        </Accordion>

        <FaqsTitle>Datos Generales</FaqsTitle>
        <Accordion>
          <div>
            <CardHeader className="faqs__card-header">
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey="0"
                className="faqs__titles w-100"
              >
                ¿Dónde se encuentra casa central?
                <img src={CaretDown} alt="caret down" className="pl-2" />
              </Accordion.Toggle>
            </CardHeader>
            <Accordion.Collapse eventKey="0">
              <CardBody className="body-wrapper">
                <p className="pt-3 d-flex align-items-center">
                  <img
                    src={Map}
                    alt="location icon"
                    className="pr-2 icon-align"
                  />
                  <span>Av. Nazca 1169, CABA.</span>
                </p>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3283.3888535521137!2d-58.476740985386!3d-34.619612465844966!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcc9edf9ade16d%3A0x3c311febf62899b3!2sAv.+Nazca+1169%2C+C1416ASA+CABA!5e0!3m2!1ses!2sar!4v1565892350803!5m2!1ses!2sar"
                  width="100%"
                  frameBorder="0"
                  style={{ border: "0" }}
                  allowFullScreen=""
                  title="mapa"
                />
                <p className="pt-3 d-flex align-items-center">
                  <img
                    src={Phone}
                    alt="phone icon"
                    className="pr-2 icon-align"
                  />
                  <a href="tel:45885900">4588-5900</a>
                </p>
                <p className="pt-2 d-flex align-items-center">
                  <img src={Mail} alt="mail icon" className="pr-2 icon-align" />
                  <a
                    href="mailto:info@altasalud.com.ar"
                    className="links-inner-faqs"
                  >
                    info@altasalud.com.ar
                  </a>
                </p>
              </CardBody>
            </Accordion.Collapse>
          </div>

          <div>
            <CardHeader className="faqs__card-header">
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey="1"
                className="faqs__titles w-100"
              >
                ¿Cuál es nuestro horario de atención?
                <img src={CaretDown} alt="caret down" className="pl-2" />
              </Accordion.Toggle>
            </CardHeader>
            <Accordion.Collapse eventKey="1">
              <CardBody className="body-wrapper">
                <p>
                  Nuestro horario de atención tanto presencial como por teléfono
                  es de Lunes a Viernes de 8 a 18 horas.
                </p>
              </CardBody>
            </Accordion.Collapse>
          </div>

          <div>
            <CardHeader className="faqs__card-header">
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey="2"
                className="faqs__titles w-100"
              >
                ¿Qué hacer ante una urgencia o emergencia?
                <img src={CaretDown} alt="caret down" className="pl-2" />
              </Accordion.Toggle>
            </CardHeader>
            <Accordion.Collapse eventKey="2">
              <CardBody className="body-wrapper">
                <p>
                  Se puede acercar a una Clínica de cartilla o puede llamar al
                  servicio de emergencias al 0810-999-2628.
                </p>
              </CardBody>
            </Accordion.Collapse>
          </div>

          <div>
            <CardHeader className="faqs__card-header">
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey="3"
                className="faqs__titles w-100"
              >
                ¿Hasta dónde es el área de cobertura?
                <img src={CaretDown} alt="caret down" className="pl-2" />
              </Accordion.Toggle>
            </CardHeader>
            <Accordion.Collapse eventKey="3">
              <CardBody className="body-wrapper">
                <p>
                  El área de cobertura de los planes de Alta Salud comprende
                  Capital Federal y Gran Buenos Aires (primer y segundo cordón).
                </p>
              </CardBody>
            </Accordion.Collapse>
          </div>
        </Accordion>

        <div ref={element}>
          <FaqsTitle>Facturación</FaqsTitle>
          <Accordion
            id="facturacion"
            defaultActiveKey={`${opened ? "0" : false}`}
          >
            <div>
              <CardHeader className="faqs__card-header">
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="0"
                  className="faqs__titles w-100"
                >
                  ¿Como abono la factura?
                  <img src={CaretDown} alt="caret down" className="pl-2" />
                </Accordion.Toggle>
              </CardHeader>
              <Accordion.Collapse eventKey="0">
                <CardBody className="body-wrapper">
                  <p>
                    <span className="d-block faqs-inner-titles">
                      PAGO MIS CUENTAS
                    </span>
                    Desde su Home Banking, o ingresando a{" "}
                    <a
                      className="links-inner-faqs"
                      href="https://pagomiscuentas.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      www.pagomiscuentas.com
                    </a>{" "}
                    por favor colocar en la parte de “identificación” el número
                    de afiliado para poder reconocer el pago realizado. Siempre
                    especifique que está pagando medicina prepaga.
                  </p>
                  <p>
                    <span className="d-block faqs-inner-titles">
                      DÉBITO DIRECTO EN CUENTA PREEXISTENTE
                    </span>
                    Si desea utilizar este servicio deberá acercarse por única
                    vez a Av. Gaona 3519/27 en el horario de 08 a 18 horas, con
                    el comprobante de CBU y DNI para completar el formulario de
                    autorización y elegir fecha de vencimiento.
                  </p>
                  <p>
                    <span className="d-block faqs-inner-titles">
                      DÉBITO DIRECTO CON APERTURA DE CUENTA EN BANCO GALICIA
                    </span>
                    Usted tiene la posibilidad de abrir una cuenta en Banco
                    Galicia Sucursal Flores Norte concurriendo a la misma
                    obteniendo importantes beneficios.
                  </p>
                  <p>
                    <span className="d-block faqs-inner-titles">
                      PAGO FÁCIL y/o RAPIPAGO
                    </span>
                    Con su factura en cualquier puesto de cobro.
                  </p>
                  <p>
                    <span className="d-block faqs-inner-titles">
                      DÉBITO AUTÓMATICO DE TARJETA VISA / MASTERCARD
                    </span>
                    Si desea utilizar este servicio deberá acercarse por única
                    vez a Av. Gaona 3519/27, con su tarjeta y DNI para completar
                    el formulario de autorización o por mail a{" "}
                    <a
                      className="links-inner-faqs"
                      href="mailto: facturacion@altasalud.com.ar"
                    >
                      facturacion@altasalud.com.ar
                    </a>
                  </p>
                  <p>
                    <span className="d-block faqs-inner-titles">
                      DEPÓSITOS O TRANSFERENCIA BANCARIA A LA CUENTA DE LA
                      EMPRESA
                    </span>
                    Banco Galicia. Solicitar datos al personal administrativo
                    llamando al 4588-5900 o vía mail a{" "}
                    <a
                      className="links-inner-faqs"
                      href="mailto: gestion@altasalud.com.ar"
                    >
                      gestion@altasalud.com.ar
                    </a>
                  </p>
                </CardBody>
              </Accordion.Collapse>
            </div>

            <div>
              <CardHeader className="faqs__card-header">
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="1"
                  className="faqs__titles w-100"
                >
                  ¿Qué sucede si abono la factura fuera de término?
                  <img src={CaretDown} alt="caret down" className="pl-2" />
                </Accordion.Toggle>
              </CardHeader>
              <Accordion.Collapse eventKey="1">
                <CardBody className="body-wrapper">
                  <p>
                    Las facturas abonadas pasada la segunda fecha de vencimiento
                    serán consideradas fuera de término y se abonaran con un
                    recargo en Av. Gaona 3519/27.
                  </p>
                </CardBody>
              </Accordion.Collapse>
            </div>

            <div>
              <CardHeader className="faqs__card-header">
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="2"
                  className="faqs__titles w-100"
                >
                  ¿Cómo hago para solicitar la factura por mail?
                  <img src={CaretDown} alt="caret down" className="pl-2" />
                </Accordion.Toggle>
              </CardHeader>
              <Accordion.Collapse eventKey="2">
                <CardBody className="body-wrapper">
                  <ul className="list-unstyled">
                    <li className="d-flex">
                      <img
                        src={Mail}
                        alt="mail icon"
                        className="pr-2 icon-align"
                      />
                      <span>Vía mail a</span>{" "}
                      <a
                        href="mailto: afiliaciones@altasalud.com.ar"
                        className="links-inner-faqs ml-1"
                      >
                        afiliaciones@altasalud.com.ar
                      </a>
                    </li>
                    <li className="d-flex">
                      <img
                        src={Phone}
                        alt="phone icon"
                        className="pr-2 icon-align"
                      />
                      <span>
                        Telefónicamente al <a href="tel:45885900">4588-5900</a>.
                      </span>
                    </li>
                  </ul>

                  <p>
                    La factura enviada por email tiene la misma validez que la
                    factura impresa y se puede abonar con los mismos medios de
                    pago descriptos anteriormente.
                  </p>
                </CardBody>
              </Accordion.Collapse>
            </div>

            <div>
              <CardHeader className="faqs__card-header">
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="3"
                  className="faqs__titles w-100"
                >
                  ¿Se puede abonar con facturas anteriores?
                  <img src={CaretDown} alt="caret down" className="pl-2" />
                </Accordion.Toggle>
              </CardHeader>
              <Accordion.Collapse eventKey="3">
                <CardBody className="body-wrapper">
                  <p>
                    Para abonar el mes en curso solo se puede pagar con la
                    factura de ese mes y no de meses anteriores.
                  </p>
                </CardBody>
              </Accordion.Collapse>
            </div>

            <div>
              <CardHeader className="faqs__card-header">
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="4"
                  className="faqs__titles w-100"
                >
                  ¿Cómo doy de baja el debito automático?
                  <img src={CaretDown} alt="caret down" className="pl-2" />
                </Accordion.Toggle>
              </CardHeader>
              <Accordion.Collapse eventKey="4">
                <CardBody className="body-wrapper">
                  <p>
                    Para solicitar la baja del débito automático usted puede
                    contactarse al <a href="tel:45885900">4588-5900</a> o vía
                    mail a{" "}
                    <a
                      href="mailto: facturación@altasalud.com.ar"
                      className="links-inner-faqs"
                    >
                      facturación@altasalud.com.ar
                    </a>{" "}
                    /{" "}
                    <a
                      href="mailto: afiliaciones@altasalud.com.ar"
                      className="links-inner-faqs"
                    >
                      afiliaciones@altasalud.com.ar
                    </a>
                  </p>
                </CardBody>
              </Accordion.Collapse>
            </div>

            <div>
              <CardHeader className="faqs__card-header">
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="5"
                  className="faqs__titles w-100"
                >
                  ¿Cómo puedo saber si tengo cuotas pendientes?
                  <img src={CaretDown} alt="caret down" className="pl-2" />
                </Accordion.Toggle>
              </CardHeader>
              <Accordion.Collapse eventKey="5">
                <CardBody className="body-wrapper">
                  <p>
                    En la factura mensual aparece si hay un “saldo pendiente” y
                    especifica cuanto se debe. Para evitar saldos en sus
                    facturas siempre trate de abonar antes de la primer fecha de
                    vencimiento.
                  </p>
                </CardBody>
              </Accordion.Collapse>
            </div>
          </Accordion>
        </div>

        <FaqsTitle>Odontología</FaqsTitle>
        <Accordion>
          <div>
            <CardHeader className="faqs__card-header">
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey="0"
                className="faqs__titles w-100"
              >
                ¿Con quién me debo contactar para acceder a los servicios de
                odontología?
                <img src={CaretDown} alt="caret down" className="pl-2" />
              </Accordion.Toggle>
            </CardHeader>
            <Accordion.Collapse eventKey="0">
              <CardBody className="body-wrapper">
                <p>
                  Se debe contactar con el centro de Auditoria Odontológica al
                  4816-1057 | 5238-1884 | 5238-1885, por email a <a
                    className="links-inner-faqs ml-1"
                    href="mailto: auditoria@movident.com.ar"
                  >auditoria@movident.com.ar</a> o ir a su sucursal en Cerrito 822, 7º piso,
                  CABA para que le informen de los servicios que tiene
                  disponible y la derivación. 
                </p>
              </CardBody>
            </Accordion.Collapse>
          </div>
        </Accordion>

        <FaqsTitle>Quiero ser un prestador</FaqsTitle>
        <Accordion>
          <div>
            <CardHeader className="faqs__card-header">
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey="0"
                className="faqs__titles w-100"
              >
                ¿Cómo puedo formar parte de la cartilla de prestadores o del
                cuerpo profesional de los centros propios?
                <img src={CaretDown} alt="caret down" className="pl-2" />
              </Accordion.Toggle>
            </CardHeader>
            <Accordion.Collapse eventKey="0">
              <CardBody className="body-wrapper">
                <p>
                  Quienes quieran sumarse a nuestro equipo, en centros propios
                  y/o cartilla de prestadores pueden enviar su CV o carta de
                  presentación a
                  <a
                    className="links-inner-faqs ml-1"
                    href="mailto: gerencia@altasalud.com.ar"
                  >
                    gerencia@altasalud.com.ar
                  </a>
                </p>
              </CardBody>
            </Accordion.Collapse>
          </div>
        </Accordion>

        <FaqsTitle>Red de Prestadores</FaqsTitle>
        <Accordion>
          <div>
            <CardHeader className="faqs__card-header">
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey="0"
                className="faqs__titles w-100"
              >
                ¿Cómo accedo a la cartilla medica?
                <img src={CaretDown} alt="caret down" className="pl-2" />
              </Accordion.Toggle>
            </CardHeader>
            <Accordion.Collapse eventKey="0">
              <CardBody className="body-wrapper">
                <p>
                  Podrá acceder a la red de prestadores desde la cartilla online
                  especificando los parámetros de su búsqueda (localidad,
                  especialidad) accederá al detalle de los profesionales
                  comprendidos en las categorías seleccionadas a través del
                  portal web o simplemente por nuestro sitio web{" "}
                  <a
                    className="links-inner-faqs"
                    href="http://www.altasalud.com.ar/"
                  >
                    www.altasalud.com.ar
                  </a>
                </p>
              </CardBody>
            </Accordion.Collapse>
          </div>
        </Accordion>

        <FaqsTitle>Residencia Médica</FaqsTitle>
        <Accordion>
          <div>
            <CardHeader className="faqs__card-header">
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey="0"
                className="faqs__titles w-100"
              >
                ¿Cómo registrarme para hacer la residencia médica en la Clínica
                Alta Salud?
                <img src={CaretDown} alt="caret down" className="pl-2" />
              </Accordion.Toggle>
            </CardHeader>
            <Accordion.Collapse eventKey="0">
              <CardBody className="body-wrapper">
                <p>
                  Para registrarse y ver si hay vacantes en el programa de
                  residentes siga el siguiente{" "}
                  <Link to="/residencia-medica" className="links-inner-faqs">
                    link
                  </Link>
                  .
                </p>
              </CardBody>
            </Accordion.Collapse>
          </div>
        </Accordion>

        <FaqsTitle>Tramites Generales</FaqsTitle>
        <Accordion>
          <div>
            <CardHeader className="faqs__card-header">
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey="0"
                className="faqs__titles w-100"
              >
                Pérdida, robo o deterioro de la credencial.
                <img src={CaretDown} alt="caret down" className="pl-2" />
              </Accordion.Toggle>
            </CardHeader>
            <Accordion.Collapse eventKey="0">
              <CardBody className="body-wrapper">
                <p>Podes solicitar una credencial de las siguientes maneras:</p>
                <ul className="list-unstyled">
                  <li className="mb-2 d-flex align-items-center">
                    <img
                      src={Check}
                      alt="check icon"
                      className="icon-align pr-2"
                    />{" "}
                    <span>A través de nuestro portal web.</span>
                  </li>
                  <li className="mb-2 d-flex align-items-center">
                    <img src={Map} alt="Map icon" className="icon-align pr-2" />{" "}
                    <span>
                      Presentándose en Av. Gaona 3519/27 con su DNI para
                      acreditar tu identidad.
                    </span>
                  </li>
                  <li className="mb-2 d-flex align-items-center">
                    <img
                      src={Mail}
                      alt="Mail icon"
                      className="icon-align pr-2"
                    />{" "}
                    <span>
                      Vía mail a{" "}
                      <a
                        className="links-inner-faqs"
                        href="mailto: afiliaciones@altasalud.com.ar"
                      >
                        afiliaciones@altasalud.com.ar
                      </a>
                    </span>
                  </li>
                </ul>
              </CardBody>
            </Accordion.Collapse>
          </div>

          <div>
            <CardHeader className="faqs__card-header">
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey="1"
                className="faqs__titles w-100"
              >
                ¿Cómo solicito la baja del servicio?
                <img src={CaretDown} alt="caret down" className="pl-2" />
              </Accordion.Toggle>
            </CardHeader>
            <Accordion.Collapse eventKey="1">
              <CardBody className="body-wrapper">
                <p>
                  Debe presentarse el titular o adherente con el DNI en Av.
                  Gaona 3519/27, antes del 25 de cada mes con el último recibo
                  pago de la cuota. Las bajas son dadas de forma fehaciente y no
                  de forma telefónica.
                </p>
              </CardBody>
            </Accordion.Collapse>
          </div>

          <div>
            <CardHeader className="faqs__card-header">
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey="2"
                className="faqs__titles w-100"
              >
                ¿Cómo puedo cambiar mi plan?
                <img src={CaretDown} alt="caret down" className="pl-2" />
              </Accordion.Toggle>
            </CardHeader>
            <Accordion.Collapse eventKey="2">
              <CardBody className="body-wrapper">
                <p>
                  Si desea cambiar su plan se deberá acercar al sector de
                  Afiliaciones en Av. Gaona 3519/27 para informarse de los
                  nuevos beneficios y valores.
                </p>
              </CardBody>
            </Accordion.Collapse>
          </div>

          <div>
            <CardHeader className="faqs__card-header">
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey="3"
                className="faqs__titles w-100"
              >
                ¿Cómo hago un cambio de domicilio?
                <img src={CaretDown} alt="caret down" className="pl-2" />
              </Accordion.Toggle>
            </CardHeader>
            <Accordion.Collapse eventKey="3">
              <CardBody className="body-wrapper">
                <p>
                  El cambio de domicilio lo puede hacer de forma fehaciente el
                  titular o adherente del grupo de las siguientes maneras:
                </p>
                <ul className="list-unstyled">
                  <li className="mb-2 d-flex align-items-center">
                    <img
                      src={Check}
                      alt="check icon"
                      className="icon-align pr-2"
                    />{" "}
                    <span>A través de nuestro portal web.</span>
                  </li>
                  <li className="mb-2 d-flex align-items-center">
                    <img src={Map} alt="Map icon" className="icon-align pr-2" />{" "}
                    <span>Presencialmente en Av. Gaona 3519/27.</span>
                  </li>
                  <li className="mb-2 d-flex align-items-center">
                    <img
                      src={Mail}
                      alt="Mail icon"
                      className="icon-align pr-2"
                    />
                    <span>Por mail a</span>
                    <a
                      className="links-inner-faqs ml-1"
                      href="mailto: afiliaciones@altasalud.com.ar"
                    >
                      afiliaciones@altasalud.com.ar
                    </a>
                  </li>
                </ul>
              </CardBody>
            </Accordion.Collapse>
          </div>
        </Accordion>
      </Col>
    </PageWrapper>
  );
};

export default withRouter(Content);
