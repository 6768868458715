import React from "react";
import Select from "react-select";

import { connect } from "react-redux";
import { getLocationByAreaId } from "../redux/actions/locations";
import { customStyles } from "./styles/common";

const AreasSelect = ({
  data,
  loading,
  onSelect,
  value,
  getLocationByAreaId
}) => {
  return (
    <Select
      options={data}
      value={value}
      onChange={value => {
        onSelect(value);
        getLocationByAreaId(value.value);
      }}
      placeholder="Localidades"
      isLoading={loading}
      clearable={false}
      className="basic-single"
      classNamePrefix="custom-select"
      noOptionsMessage={() => "No se encontraron datos."}
      styles={customStyles}
    />
  );
};

const mapStateToProps = state => ({
  data: state.areas.data,
  loading: state.areas.loading
});

export default connect(
  mapStateToProps,
  {
    getLocationByAreaId
  }
)(AreasSelect);
