import React from "react";
import { Link } from "react-router-dom";
import Button from "../Button";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ServiceCard = ({
  white,
  title,
  text,
  image,
  servicesUrl,
  wrapperLink,
  wrapperLinkUniversa
}) => {
  const cardInner = () => {
    return (
      <>
        <LazyLoadImage src={image} alt="icon" loading="lazy" effect="blur" />
        <hr className="home-slider_info__line" />
        <h4 className="mb-3">{title}</h4>
        {text && <p>{text}</p>}
        {servicesUrl && servicesUrl.length > 0 && (
          <div>
            {servicesUrl.map(item => (
              <>
              <Button key={item.title} type="white-outline" url={item.url}>
                {item.title}
              </Button>
              <p>&nbsp;</p>
              </>
            ))}
          </div>
        )}
      </>
    );
  };

  if (wrapperLink) {
    return (
      <Link to="/cartilla-medica">
        <article
          className={`${
            white ? "container-boxes_light" : "container-boxes_dark"
          }`}
        >
          {cardInner()}
        </article>
      </Link>
    );
  }

  if (wrapperLinkUniversa) {
    return (
      <Link to="/universal-assistance">
        <article
          className={`${
            white ? "container-boxes_light" : "container-boxes_dark"
          }`}
        >
          {cardInner()}
        </article>
      </Link>
    );
  } 

  return (
    <article
      className={`${white ? "container-boxes_light" : "container-boxes_dark"}`}
    >
      {cardInner()}
    </article>
  );
};

export default ServiceCard;
