import React from "react";
import { Link } from "react-router-dom";
import ContactForm from "./Contacto/ContactForm";

const ContactLink = ({ children, url}) => {
  const changeSubject = ()=>{
    console.log('ContactForm', ContactForm);
  };

  return (
    <Link to={url} className="footer__titles-desktop" onClick={changeSubject}>
      <h6>{children}{" "}</h6>
    </Link>
  );
};

export default ContactLink;
