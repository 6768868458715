import React from "react";
import { Link, withRouter } from "react-router-dom";
import {
  Navbar,
  Row,
  Col,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import Button from "../Button";
import { connect } from "react-redux";
import { LogOut } from "../../redux/actions/auth";
import { SocialIcon } from 'react-social-icons';

import LogoImage from "../../assets/img/logo-naranja.svg";
import MyAccount from "../../assets/img/mi_cuenta.svg";
import LogOutIcon from "../../assets/img/cerrar_sesion.svg";
import LinkActive from "../LinkActive";

//Agrego Dario 
import ReactGA from 'react-ga';
ReactGA.initialize('UA-173013637-1');


export const Event = (category, action, label) => {
  console.log(action);
  ReactGA.event({
    category: category,
    action: action,
    label: label
  });
};
//

//const url="turnos.elijasuturno.com.ar/turnos_php3/?es=alta";
const url='turnos.elijasuturno.com.ar:444/altasalud';


const DesktopNavbar = ({ token, user, LogOut, history }) => {
  return (
    <header className="d-none d-lg-flex">
      <Row className="mx-0 header__orange">
        <Col xs="2" className="p-0"></Col>
        <Col xs="7" className="p-0">
          <span>Urgencias las 24hs. 0810-999-2628</span> <span>|</span>{" "}
          <span>Administración 4588-5900</span> <span>|</span>{" "}
          <span>Clínica Alta Salud 4588-5800</span>
        </Col>
        <Col xs="1" className="p-0">&nbsp; </Col>
        <Col xs="2" className="p-0">
          <SocialIcon url="https://www.facebook.com/pg/altasaludoficial/" target='_blank' bgColor="#fff"  style={{ height: 25, width: 25, marginTop: -3 }} />
          &nbsp; &nbsp; 
          <SocialIcon url="https://www.instagram.com/altasaludoficial/" target='_blank' bgColor="#fff"  style={{ height: 25, width: 25 ,marginTop: -3 }} />
          &nbsp;&nbsp;
          <SocialIcon bgColor="#fff" network="whatsapp" url="whatsapp://send?phone=+5491159244301" style={{ height: 27, width: 27 }} />  
        </Col>

      </Row>
      <Navbar color="light" light fixed="top" className="bg-nav-as">
        <Link to="/" className="navbar-brand">
          <img src={LogoImage} alt="logo desktop" className="header__logo-as" />
        </Link>
        <Nav className="navbar__desktop" navbar >
          <NavItem>
            <LinkActive to="/nuestros-planes">Planes</LinkActive>
          </NavItem>
          <NavItem>
            <LinkActive to="/cartilla-medica">Cartilla médica</LinkActive>
          </NavItem>
          <NavItem>
            <LinkActive to="/farmacias">Farmacias</LinkActive>
          </NavItem>
          <NavItem>
            <LinkActive to="/centros-medicos">Centros médicos</LinkActive>
          </NavItem>
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
            Servicios adicionales
            </DropdownToggle>
            <DropdownMenu tag="ul" className="dropdown-menu__as" right>
              <li>
                <DropdownItem
                  className="btn-menu"
                  onClick={() => history.push("/universal-assistance")}
                >
                  Universal Assistance
                </DropdownItem>
              </li>
              <li>
                <DropdownItem
                  className="btn-menu"
                  onClick={() => history.push("/plan-vacunar")}
                >
                  Plan Vacunar
                </DropdownItem>
              </li>
              <li>
                <DropdownItem
                  className="btn-menu"
                  onClick={() => history.push("/servicios-estetica-bienestar")}
                >
                  Estética y Bienestar
                </DropdownItem>
              </li>
              
            </DropdownMenu>
          </UncontrolledDropdown>

          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
              Institucional
            </DropdownToggle>
            <DropdownMenu tag="ul" className="dropdown-menu__as" right>
              <li>
                <DropdownItem
                  className="btn-menu"
                  onClick={() => history.push("/quienes-somos")}
                >
                  Quiénes Somos
                </DropdownItem>
              </li>
              <li>
                <DropdownItem
                  className="btn-menu"
                  onClick={() => history.push("/reglamento")}
                >
                  Reglamento
                </DropdownItem>
              </li>
              <li>
                <DropdownItem
                  className="btn-menu"
                  onClick={() => history.push("/preguntas-frecuentes")}
                >
                  Preguntas Frecuentes
                </DropdownItem>
              </li>
              <li>
                <DropdownItem
                  className="btn-menu"
                  onClick={() => history.push("/contacto")}
                >
                  Contacto
                </DropdownItem>
              </li>
              <li>
                <DropdownItem
                  className="btn-menu"
                  onClick={() => history.push("/trabaja-con-nosotros")}
                >
                  Trabajá con Nosotros
                </DropdownItem>
              </li>
            </DropdownMenu>
          </UncontrolledDropdown>



          
          
        </Nav>
        {token ? (
           
          <Nav className="navbar__desktop no-hover-line" navbar>

            

            <Row className="mx-0 ">

            <Col xs="6" className="p-0"><a target="_blank"  className="btn-orange_outline" href={"https://"+url}>Turnos Online</a></Col>

           <Col xs="6" className="p-0">
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                {user && user.full_name}
              </DropdownToggle>
              <DropdownMenu tag="ul" className="dropdown-menu__as" right>
                <li>
                  <DropdownItem
                    className="btn-menu"
                    onClick={() => history.push("/panel")}
                  >
                    <img src={MyAccount} alt="my account icon" /> Mi cuenta
                  </DropdownItem>
                </li>
                <li>
                  <DropdownItem className="btn-menu" onClick={() => LogOut()}>
                    <img src={LogOutIcon} alt="logout icon" /> Cerrar sesión
                  </DropdownItem>
                </li>
              </DropdownMenu>
            </UncontrolledDropdown></Col>
           </Row>


          </Nav>
        ) : (
          <Nav className="mx-0 ml-auto" style={{minWidth: "250px"}}>
            
            <Row>
              <Col xs="8" className="p-0">
                <a target="_blank"  className="btn-orange_outline2" href={"https://" + url}>Turnos Online</a>
              </Col>

              <Col xs="2" className="p-0">
                <Button url="/login" type="orange-outline" onClick={()=> Event("Ingrese", "Logeo", "") } >
                  Ingrese
                </Button>
              </Col>
            </Row>

          </Nav>
          
        )}

      </Navbar>
    </header>
  );
};

const mapStateToProps = state => ({
  token: state.auth.token,
  user: state.auth.user
});

export default withRouter(
  connect(
    mapStateToProps,
    {
      LogOut
    }
  )(DesktopNavbar)
);
