import React from "react";
import Button from "../Button";
import { SocialIcon } from 'react-social-icons';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-173013637-1');

//ReactGA.pageview(window.location.pathname + window.location.search);

export const Event = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label
  });
};


const SlideInfo = () => {
  return (
    <div className="home-slider__info-container">
      <hr className="home-slider_info__line" />
      <span className="home-slider_info">Ventas al 0800.333.2582</span>

      <span>
        <SocialIcon bgColor="#fff" network="whatsapp" url="whatsapp://send?phone=+5491159244301" style={{ height: 27, width: 27 }} /> WhatsApp
      </span>

      <h2 className="home-slider_title">
        ¿aún no sos
        <br />
        socio?
      </h2>
      <Button url="/contacto" onClick={()=> 
	Event("Asociarme", "Redireccion", "label")
}  type="orange">
        Asociarme
      </Button>
    </div>
  );
};

export default SlideInfo;
