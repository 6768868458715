import React from "react";
import { Carousel } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";

import FirstSlide from "../../assets/img/slideshow_home.jpg";
import SecondSlide from "../../assets/img/slideshow_home_2.jpg";
import ThirdSlide from "../../assets/img/slideshow_home_3.jpg";

const SlideShow = () => {
  return (
    <Carousel className="slideshow">
      <Carousel.Item>
        <LazyLoadImage
          className="d-block w-100"
          src={FirstSlide}
          effect="blur"
          alt="First slide"
          loading="lazy"
          style={{ height: "100%" }}
        />
      </Carousel.Item>

      <Carousel.Item>
        <LazyLoadImage
          className="d-block w-100"
          src={SecondSlide}
          effect="blur"
          alt="Second slide"
          loading="lazy"
          style={{ height: "100%" }}
        />
      </Carousel.Item>

      <Carousel.Item>
        <LazyLoadImage
          className="d-block w-100"
          src={ThirdSlide}
          effect="blur"
          alt="Third slide"
          loading="lazy"
          style={{ height: "100%" }}
        />
      </Carousel.Item>
    </Carousel>
  );
};

export default SlideShow;
