import axios from "axios";
import { toast } from "react-toastify";
import { API_URL } from "../api";
import {
  LOADING_LABORATORIOS,
  GET_LABORATORIOS_SUCCESS,
  GET_LABORATORIOS_FAILURE,
  SET_LABORATORIOS_PAGE,
  RESET_LABORATORIOS
} from "./types/laboratorios";
import { LogOut } from "./auth";

export const getLaboratorios = filters => async dispatch => {
  dispatch({ type: LOADING_LABORATORIOS });

  try {
    const response = await axios.get(`${API_URL}/api/web/member/laboratory`);

    dispatch({
      type: GET_LABORATORIOS_SUCCESS,
      payload: {
        laboratory: response.data.memberLaboratory.link
      }
    });

    return response.status;
  } catch (error) {
    dispatch({ type: GET_LABORATORIOS_FAILURE });

    if (error.response && error.response.status === 422) {
      toast.error(error.response.data.message);
    } else if (error.response && error.response.status === 401) {
      toast.error(error.response.data.message);
      dispatch(LogOut());
    } else {
      toast.error("Lo sentimos, ha sucedido un error inesperado. (Error 0008)");
    }
    return error.response.status;
  }
};

export const setPage = page => ({
  type: SET_LABORATORIOS_PAGE,
  payload: {
    page
  }
});

export const resetLaboratorios = () => ({
  type: RESET_LABORATORIOS
});
