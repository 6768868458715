import React from "react";
import { Row, Col } from "reactstrap";
import MedicCenterItem from "./MedicCenterItem";

// Images
import Image1 from "../../assets/img/clinica-a-s.jpg";
import Image2 from "../../assets/img/centro_medico_as.jpg";
import Image3 from "../../assets/img/profei.jpg";
import Image4 from "../../assets/img/diagnostico_imagenes.jpg";

const MedicCenters = () => (
  <Row className="mx-0 bg-soft-grey">
    <Col xs="12" className="text-center">
      <span className="home__subtiles">centros de atención</span>
      <hr className="lines_after-titles" />
      <h2 className="grey-titles">Conocé nuestros centros propios</h2>
    </Col>
    <Col xs="12" className="mt-5">
      <Row className="mx-0">
        <MedicCenterItem title="Clínica Alta Salud" image={Image1} />
        <MedicCenterItem title="Centro Médico Alta Salud" image={Image2} />
        <MedicCenterItem
          title="PROFEI, Protección Integral Femenina"
          image={Image3}
        />
        <MedicCenterItem
          title="Centro Moreau, Diagnóstico por Imágenes y Laboratorio de Análisis Clínicos"
          image={Image4}
        />
      </Row>
    </Col>
  </Row>
);

export default MedicCenters;
