import React from "react";
import Tags from "../components/Meta/Tags";
import Banner from "../components/Banner";
import Content from "../components/Faqs/Content";

import ImageBanner from "../assets/img/preguntas_frecuentes.jpg";

const PreguntasFrecuentes = () => (
  <>
   <Tags 
    title="Preguntas frecuentes" 
    description="Todas las respuestas que buscas en un solo lugar." 
    keywords="Más información, Consultas, Preguntas, Facturacion, Medios de pago, Aun no sos socio, Asociarme, Odontologia, Asistencia al viajero, Centros medicos propios, Datos generales" 
    canonical="/preguntas-frecuentes" />
    <Banner title="Preguntas Frecuentes" image={ImageBanner} />
    <Content />
  </>
);

export default PreguntasFrecuentes;
