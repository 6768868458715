import React from "react";
import { Container, Row, Col } from "reactstrap";
import Center from "./Center";

import Image1 from "../../assets/img/clinica-a-s.jpg";
import Image2 from "../../assets/img/centro_medico_as.jpg";
import Image3 from "../../assets/img/profei.jpg";
import Image4 from "../../assets/img/diagnostico_imagenes.jpg";

import AfiliacionesImg from "../../assets/img/afiliaciones-nuevas.svg";
import ContenidoImg from "../../assets/img/contenido-orientacion.svg";
import SocioImg from "../../assets/img/all-socio-turnos.svg";
import CheckImg from "../../assets/img/check.svg";
import FacturaImg from "../../assets/img/att-socio-factura.svg";

//const url="turnos.elijasuturno.com.ar/turnos_php3/?es=alta";
const url='turnos.elijasuturno.com.ar:444/altasalud';

const clinics = [
  {
    title: "Clínica Alta Salud",
    image: Image1,
    address: "Av. Nazca 1169, C1416 CABA",
    phone: "011 4588-5800 Líneas rotativas",
    schelude: "Atención las 24 horas",
    turnonline:"",
    mapUrl:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3283.388678902689!2d-58.476740984769954!3d-34.619616880455396!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcc9edf9ade16d%3A0x3c311febf62899b3!2sAv.+Nazca+1169%2C+C1416ASA+CABA!5e0!3m2!1ses!2sar!4v1561471988784!5m2!1ses!2sar",
    benefits:
      "Todas las especialidades Médicas, Clínicas y Quirúrgicas en adultos | Diagnóstico por Imágenes | Laboratorio | Urgencias y Emergencias las 24 horas | Sistema informatizado de Historias Clínicas | Sucursal de Servicios al Afiliado de Alta Salud",
  },
  {
    title: "CENTRO MÉDICO ALTA SALUD",
    image: Image2,
    address: "Av. Gaona 3519/27, C1416, CABA",
    phone: "011 4588-5900 Líneas rotativas",
    schelude: "Lunes a Viernes de 8 a 18 horas",
    turnonline:<a target="_blank"  className="btn-orange_outline" href={"https://"+url}>Turnos Online</a>,
    mapUrl:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3283.3525982285573!2d-58.47694878476987!3d-34.62052888045513!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcc9c783e0d97d%3A0x3818332eb28424c7!2sAv.+Gaona+3519%2C+C1416DSK+CABA!5e0!3m2!1ses!2sar!4v1563569026012!5m2!1ses!2sar",
    benefits:
      "Consultorios Externos | Atención de todas las Especialidades Clínicas y Quirurgícas | Oftalmología |Otorrinolaringología | Fonoaudiología  | Salud Mental | Kinesiología, Gimnasio, Aparatología para Rehabilitación |  Prácticas y Estudios |  Consulta de demanda espontanea de Lun. a Vier. de 8 a 18 hs | Servicios de Estética y Bienestar",
  },
  {
    title: "PROFEI, Protección Femenina Integral",
    image: Image3,
    address: "Av. Nazca 1193, C1416, CABA",
    phone: "011 4588-0870",
    schelude: "Lunes a Viernes de 9:30 a 15hs",
    turnonline:"",
    mapUrl:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3283.393525783796!2d-58.47701154918677!3d-34.61949436574414!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcc9edfea8f543%3A0xf783d23a7c24e073!2sAv.+Nazca+1193%2C+C1416ASA+CABA!5e0!3m2!1ses!2sar!4v1563569109898!5m2!1ses!2sar",
    benefits:
      "Patologías Ginecológicas Clínica y Quirúrgica |Ginecología General Patología Mamaria | Adolescencia e Infantojuvenil | Planificación Familiar | Climaterio y menopausia | Criocirugía | Uroginecología | Oncología |Practicas y Estudios",
  },

  {
    title:
      "CENTRO MOREAU, DIAGNÓSTICO POR IMÁGENES Y LABORATORIO DE ANALISIS CLÍNICOS",
    image: Image4,
    address: "Av. Nazca 1089, C1416, CABA",
    phone: "011 4582-2555",
    schelude: "Lun a Vier. de 7:30 a 20 hs | Sáb. de 8 a 18:30hs",
    turnonline:"",
    mapUrl:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3283.344057219557!2d-58.476139549186684!3d-34.620744765810436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcc9f270e40c4f%3A0x6723d495370cc98b!2sAv.+Nazca+1089%2C+C1406AJJ+CABA!5e0!3m2!1ses!2sar!4v1563569166754!5m2!1ses!2sar",
    benefits:
      "Resonancia Magnética | Tomografía Computada | Radiología Digital – Intervencionismo General Guiado por Imágenes | Diagnostico Cardiológico | Ecografía | Densitometría Ósea | Centro de la Mujer (Diagnostico por Imágenes) | Mamografía Digital | Ecografías | Intervencionismo Mamario Guiado por Imágenes | Laboratorio de Análisis Clínico",
    whatsapp: "011 2808-0002",
  },
];

const Content = () => {
  return (
    <Container fluid className="p-0">
      <Container>
        <Row className="mx-0 row-eq-height justify-content-center my-70 section_top_responsive mb-5">
          {clinics.map((clinic) => (
            <Center key={clinic.title} {...clinic} />
          ))}
        </Row>
      </Container>
      <Row className="mx-0 bg-grey justify-content-center">
        <Col xs="12" sm="8">
          <h2 className="resultado-busqueda__title text-initial">
            Atención al cliente
          </h2>
          <span>
            Hablando con uno de nuestros representantes usted podrá realizar los
            siguientes trámites:
          </span>
          <ul className="mt-4 att-cliente__items">
            <li className="att-socio__li">
              {" "}
              <img
                src={AfiliacionesImg}
                className="att-socio-icons"
                alt="afiliaciones icon"
              />{" "}
              Afiliaciones nuevas
            </li>
            <li className="att-socio__li">
              {" "}
              <img
                src={ContenidoImg}
                className="att-socio-icons"
                alt="socios icon"
              />{" "}
              Contención y orientación general
            </li>
            <li className="att-socio__li">
              {" "}
              <img
                src={SocioImg}
                className="att-socio-icons"
                alt="socios att icon"
              />{" "}
              Turnos
            </li>
            <li className="att-socio__li">
              <div className="d-flex">
                <div>
                  <img
                    src={CheckImg}
                    className="att-socio-icons"
                    alt="checkicon"
                  />
                </div>
                <div>Autorizaciones de órdenes médicas | medicamentos</div>
              </div>
            </li>
            <li className="att-socio__li">
              {" "}
              <img
                src={FacturaImg}
                className="att-socio-icons"
                alt="factura icon"
              />{" "}
              Facturación
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
};

export default Content;
