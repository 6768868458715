import React, { useEffect, useState } from "react";
import Select from "react-select";

import { connect } from "react-redux";
import { getSpecialties } from "../redux/actions/specialties";
import { customStyles } from "./styles/common";

const SpecialtiesSelect = ({ getSpecialties, data, loading, onSelect }) => {
  const [value, setValue] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      await getSpecialties();
    };

    fetchData();
  }, [getSpecialties]);

  return (
    <Select
      className="basic-single"
      classNamePrefix="custom-select"
      options={data}
      value={value}
      onChange={value => {
        setValue(value);
        onSelect(value.value);
      }}
      placeholder="Especialidades"
      styles={customStyles}
      noOptionsMessage={() => "No se encontraron datos."}
      isLoading={loading}
      clearable={false}
    />
  );
};

const mapStateToProps = state => ({
  data: state.specialties.data,
  loading: state.specialties.loading
});

export default connect(
  mapStateToProps,
  {
    getSpecialties
  }
)(SpecialtiesSelect);
