import React, { useState } from "react";
import { Col, Collapse } from "reactstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";

import Phone from "../../assets/img/phone-icon.svg";
import Map from "../../assets/img/maplocation.svg";
import Whatsapp from "../../assets/img/whatsap-icon.svg";
import ArrowNaranja from "../../assets/img/down-naranja.svg";
import Clock from "../../assets/img/clock-regular.svg";

//const url="turnos.elijasuturno.com.ar/turnos_php3/?es=alta";
const url='turnos.elijasuturno.com.ar:444/altasalud';

const Center = ({
  title,
  image,
  address,
  phone,
  schelude,
  turnonline,
  mapUrl,
  benefits,
  whatsapp
}) => {
  const [collapse, setCollapse] = useState(false);

  return (
    <Col xs="12" sm="6" className="mb-5">
      <h4 className="resultado-busqueda__title doble-line-title">{title}</h4>
      <LazyLoadImage
        src={image}
        alt="clinic"
        className="w-100 b-block my-3"
        loading="lazy"
        effect="blur"
      />
      <div className="resultado-busqueda__text">
        <img
          src={Map}
          alt="location"
          className="pr-2 centros-medicos__icons-info icon-align"
        />
        {address}
      </div>
      <span className="d-block resultado-busqueda__text">
        <img
          src={Phone}
          alt="phone icon"
          className="pr-2 pr-2 centros-medicos__icons-info icon-align"
        />
        {phone}
        {whatsapp && (
          <>
            <span className="px-2">|</span>
            <img src={Whatsapp} alt="whatsapp logo" className="pr-2" />
            {whatsapp}
          </>
        )}
      </span>
      <span className="d-block resultado-busqueda__text">
        <img
          src={Clock}
          alt="phone icon"
          className="pr-2 pr-2 centros-medicos__icons-info icon-align"
        />
        {schelude}
      </span>
      <button
        onClick={() => setCollapse(!collapse)}
        className="d-block location-map-link my-3"
      >
        Ver ubicación{" "}
        <img
          src={ArrowNaranja}
          alt="arrow"
          className={`medical-center-arrow ${collapse && "up"}`}
        />
      </button>
      {turnonline}
      <Collapse isOpen={collapse}>
        <iframe
          src={mapUrl}
          width="100%"
          frameBorder="0"
          style={{ border: "0" }}
          allowFullScreen
          title={title}
        />
      </Collapse>
      <span className="d-block mt-4">Algunas prácticas que se realizan:</span>
      <h6 className="planes__beneficios mt-2">{benefits}</h6>
    </Col>
  );
};

export default Center;
