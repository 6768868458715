import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Col, Input, Row, Spinner } from "reactstrap";
import { getFormTypeByWord } from "../lib/getFormTypeBySlug";
import { addContactForm } from "../redux/actions/common";
import { getFormTypes, resetFormTypes } from "../redux/actions/form_types";
import DatePicker from "./DatePicker";
import EditableInput from "./EditableInput";

const UptPersonalDataForm = ({
  addContactForm,
  user,
  getFormTypes,
  formTypes,
  loading,
  history,
  resetFormTypes,
}) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const useInput = (initialValue) => {
    const [fields, setFields] = useState(initialValue);

    const changeInputField = (obj) => {
      const { value, name } = obj;

      setFields({ ...fields, [name]: value });
    };

    return [fields, changeInputField];
  };

  const [isSubmitting, setSubmitting] = useState(false);
  const [formTypeId, setFormTypeId] = useState(null);

  const [fields, setFields] = useInput({
    direccion: user ? user.address : "",
    email: user ? user.email : "",
    telefono: user ? user.phone : "",
    extra: "",
    born_date: "",
  });

  useEffect(() => {
    const fetchFormTypes = async () => await getFormTypes(true);

    fetchFormTypes();
  }, [getFormTypes]);

  useEffect(() => {
    if (formTypes.length > 0) {
      setFormTypeId(getFormTypeByWord("actualizacion", formTypes));
    }
  }, [formTypes]);

  useEffect(() => {
    return () => resetFormTypes();
  }, [resetFormTypes]);

  const handleSubmit = async () => {
    setSubmitting(true);

    const parsedMessage = {
      name: user.full_name,
      title: "Actualización de Datos Personales",
      email: user.email,
      phone: user.phone || "",
      contact_form_type_id: formTypeId || "",
      message: `Datos a editar del usuario: <br />
        <ul>
          <li>Nombre: ${user.full_name}.</li>
          <li>Tipo y nro de documento: ${user.personal_id_type} ${
        user.personal_id
      }.</li>
          <li>Dirección: ${fields.direccion}.</li>
          <li>Mail: ${fields.email}.</li>
          <li>Teléfono: ${fields.telefono}.</li>
          <li>Plan: ${user.plan}</li>
          <li>Fecha de Nacimiento: ${moment(fields.born_date).format(
            "DD/MM/YYYY"
          )}</li>
        </ul>
        Comentarios extras: <br />
        ${fields.extra}
      `,
    };

    const response = await addContactForm(parsedMessage);

    setSubmitting(false);

    if (response === 200) {
      history.push("/panel/tramites");
    }
  };

  const formattedDate = moment(fields.born_date).format("DD/MM/YYYY");

  return (
    <div>
      <fieldset
        disabled={loading || isSubmitting}
        aria-busy={loading || isSubmitting}
      >
        <Row>
          <Col xs={12} md={6}>
            <div className="dd-pp__lines d-flex">
              <div className="dd-pp__item-title">Nombre:</div>
              <p className="mb-0">{user.full_name}</p>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div className="dd-pp__lines d-flex">
              <div className="dd-pp__item-title">Tipo y nro de documento:</div>
              <p className="mb-0">
                {user.personal_id_type} {user.personal_id}
              </p>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div className="dd-pp__lines d-flex">
              <div className="dd-pp__item-title">Fecha de nacimiento:</div>

              <div className="editable-wrapper position-relative">
                <Input
                  className={`form-control__round`}
                  type="text"
                  placeholder="Fecha de nacimiento"
                  onClick={() => setShowCalendar(true)}
                  onChange={() => null}
                  value={
                    formattedDate === "Invalid date"
                      ? user.born_date.length > 0
                        ? moment(user.born_date).format("DD/MM/YYYY")
                        : ""
                      : formattedDate
                  }
                />

                <DatePicker
                  active={showCalendar}
                  value={fields.born_date}
                  onChange={(date) =>
                    setFields({
                      name: "born_date",
                      value: date,
                    })
                  }
                  closeModal={() => setShowCalendar(false)}
                />
              </div>
            </div>
          </Col>

          <Col xs={12} md={6}>
            <div className="dd-pp__lines d-flex">
              <div className="dd-pp__item-title">Dirección:</div>
              <EditableInput
                name="direccion"
                value={fields.direccion}
                onChange={setFields}
              />
            </div>
          </Col>

          <Col xs={12} md={6}>
            <div className="dd-pp__lines d-flex">
              <div className="dd-pp__item-title">Mail:</div>
              <EditableInput
                name="email"
                value={fields.email}
                onChange={setFields}
              />
            </div>
          </Col>

          <Col xs={12} md={6}>
            <div className="dd-pp__lines d-flex">
              <div className="dd-pp__item-title">Teléfono:</div>
              <EditableInput
                name="telefono"
                value={fields.telefono}
                onChange={setFields}
              />
            </div>
          </Col>

          <Col xs={12} md={6}>
            <div className="dd-pp__lines d-flex">
              <div className="dd-pp__item-title">Plan:</div>
              <p className="mb-0">{user.plan}</p>
            </div>
          </Col>

          <Row className="w-100 mt-3">
            <Col xs={12} md={6}>
              <Input
                type="textarea"
                name="extra"
                value={fields.extra}
                onChange={(e) =>
                  setFields({ name: e.target.name, value: e.target.value })
                }
                className="form-control__round plan-select py-3"
                placeholder="Si tienes algún comentario extra, escribelo aquí..."
              />
            </Col>
            <Col xs={12} md={12}>
              <button
                type="button"
                className={`d-block btn-orange mt-4`}
                onClick={handleSubmit}
              >
                {isSubmitting ? (
                  <Spinner size="sm" color="light" />
                ) : (
                  <>Enviar</>
                )}
              </button>
            </Col>
          </Row>
        </Row>
      </fieldset>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  formTypes: state.formTypes.formTypes,
  loading: state.formTypes.loading,
});

export default withRouter(
  connect(mapStateToProps, {
    addContactForm,
    getFormTypes,
    resetFormTypes,
  })(UptPersonalDataForm)
);
