import React from "react";
import { Row, Col } from "reactstrap";

import PanelTitle from "../PanelTitle";
import Icon from "../../assets/img/tramites.svg";
import SugerenciasMejorasForm from "../SugerenciasMejorasForm";

const SolicitudInfo = () => {
  return (
    <>
      <PanelTitle icon={Icon}>Trámites / Sugerencias y Mejoras</PanelTitle>
      <Row className="mx-0 mt-5">
        <Col xs={12} md={6} className="pb-4">
          <h6 className="mb-3">
            Indique a continuación que sugerencias y/o mejoras tiene para
            nosotros:
          </h6>
          <SugerenciasMejorasForm />
        </Col>
      </Row>
    </>
  );
};

export default SolicitudInfo;
