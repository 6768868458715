import React from "react";
import { Row, Col } from "reactstrap";

import PanelTitle from "../PanelTitle";
import Icon from "../../assets/img/medicamentos.svg";
import NaranjaArrow from "../../assets/img/arrow-naranja.svg";

import FormDietoterapia from "../../assets/FORMULARIO_DIETOTERAPIA.pdf";

//import FormDiabetes from "../../assets/FORMULARIO_DE_DBT_06-22.pdf";
import FormDiabetes from "../../assets/FORMULARIO_DE_DBT_2022-11.pdf";
import FormMedicamentos from "../../assets/FORMULARIO_MEDICACION_ONCOLOGICA_01.pdf";

const Medicamentos = () => ( 
  <>
    <PanelTitle icon={Icon}>Medicamentos</PanelTitle>
    <Row className="mt-3 mt-sm-5">
      <Col>
      <a
          href={FormDietoterapia}
          target="_blank"
          rel="noopener noreferrer"
          className="medicamentos-links pb-2"
          download
        >
          <img src={NaranjaArrow} alt="naranja arrow" /> Formulario Dietoterápicos
        </a>

        <a
          href={FormDiabetes}
          target="_blank"
          rel="noopener noreferrer"
          className="medicamentos-links pb-2"
          download
        >
          <img src={NaranjaArrow} alt="naranja arrow" /> Formulario Diabetes Res 2820/22
        </a>
        <a
          href={FormMedicamentos}
          target="_blank"
          rel="noopener noreferrer"
          className="medicamentos-links pb-2"
          download
        >
          <img src={NaranjaArrow} alt="naranja arrow" /> Formulario Medicación Oncológica
        </a>
      </Col>
    </Row>
  </>
);

export default Medicamentos;
