import React, { useEffect, useState, useCallback } from "react";
import { Table, Row, Col } from "reactstrap";

import { connect } from "react-redux";
import {
  getImagenes,
  setPage,
  resetImagenes
} from "../../redux/actions/imagenes";

import PanelTitle from "../PanelTitle";
import Icon from "../../assets/img/imagenes.svg";
import TableLoading from "../TableLoading";
import ImagenesItem from "./ImagenesItem";
// import Paginator from "../Paginator";

const Imagenes = ({
  imagenes,
  loading,
  history,
  getImagenes,
  resetImagenes
}) => {
  const [fetchData, setFetchData] = useState(false);

  const getData = useCallback(async () => {
    const response = await getImagenes();
    if (response !== 200) {
      history.push("/panel");
    }
  }, [getImagenes, history]);

  useEffect(() => {
    getData();
  }, [getData]);

  // const handlePaginator = page => {
  //   setPage(page);
  //   setFetchData(true);
  // };

  useEffect(() => {
    if (fetchData) {
      getData();
      setFetchData(false);
    }
  }, [fetchData, getData]);

  useEffect(() => {
    return () => resetImagenes();
  }, [resetImagenes]);

  return (
    <>
      <PanelTitle icon={Icon}>Imagenes</PanelTitle>
      {loading ? (
        <div className="mt-5">
          <TableLoading />
        </div>
      ) : (
        <Row noGutters>
          <Col>
            {imagenes.length === 0 ? (
              <h2 className="my-5">No se encontro nada.</h2>
            ) : (
              <>
                <Table responsive className="tables-dashboard mt-3 mt-sm-5">
                  <thead>
                    <tr>
                      <th scope="col">Fecha</th>
                      <th scope="col">Tipo de estudio</th>
                      <th scope="col">Estado</th>
                      <th scope="col">Link PDF</th>
                      <th scope="col">Link Imagenes</th>
                    </tr>
                  </thead>
                  <tbody>
                    {imagenes.map((item, index) => (
                      <ImagenesItem key={index} {...item} />
                    ))}
                  </tbody>
                </Table>
              </>
            )}
          </Col>
        </Row>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  imagenes: state.imagenes.imagenes,
  loading: state.imagenes.loading
});

export default connect(mapStateToProps, {
  getImagenes,
  setPage,
  resetImagenes
})(Imagenes);
