import React from "react";
import Tags from "../components/Meta/Tags";
import Banner from "../components/Banner";
import Content from "../components/VacunarPlan/Content";

import ImageBanner from "../assets/img/plan-vacunar.jpg";

const PlanVacunar = () => (
  <>
    <Tags 
      title="Vacunatorios" 
      description="Plan vacunar ofrece el más completo servicio de prevención y cuidado." 
      keywords="vacunas, inyecciones , vacuna antigripal, prevencion , refuerso inmunologico, prevension de la neumonia, higiene y seguridad, calendario de vacunacion" 
      canonical="/plan-vacunar" />
    <Banner title="Plan vacunar" image={ImageBanner} size="6" height="pv" />
    <Content />
  </>
);

export default PlanVacunar;
