// COMMENT: CREATE THIS FILE
import {
    GET_PHARMACIES_NAME_SUCCESS,
    GET_PHARMACIES_NAME_FAILURE,
    LOADING_PHARMACIES_NAME,
  } from "../actions/types/pharmaciesName";
  
  const initialState = {
    data: [],
    loading: false,
  };
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case LOADING_PHARMACIES_NAME: {
        return {
          ...state,
          loading: true,
        };
      }
  
      case GET_PHARMACIES_NAME_SUCCESS: {
        return {
          ...state,
          loading: false,
          data: [...action.payload.data],
        };
      }
  
      case GET_PHARMACIES_NAME_FAILURE: {
        return {
          ...state,
          loading: false,
        };
      }
  
      default:
        return state;
    }
  };
  