import {
  GET_SPECIALTIES_LOADING,
  GET_SPECIALTIES_SUCCESS,
  GET_SPECIALTIES_FAILURE
} from "../actions/types/specialties";

const initialState = {
  data: [],
  loading: true
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SPECIALTIES_LOADING: {
      return {
        ...state,
        loading: true
      };
    }

    case GET_SPECIALTIES_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: [...action.payload.data]
      };
    }

    case GET_SPECIALTIES_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};
