import React from "react";
import { Row, Col } from "reactstrap";
import SignInForm from "./SignInForm";
import { LazyLoadImage } from "react-lazy-load-image-component";
import LoginImage from "../assets/img/login.jpg";

const SignIn = () => (
  <Row className="row-eq-height align-items-center mx-0 section__ppal min-height-sm-100">
    <Col
      xs={12}
      md={5}
      lg={{ size: "4", offset: "1" }}
      xl={3}
      className="px-4 py-5"
    >
      <hr className="section-banner_info__line" />
      <h3 className="login__title">Ingresa en Alta Salud</h3>
      <p>Si ingresa por primera vez , repita su DNI como contraseña.</p>
      <p>Completa tus datos a continuación.</p>
      <SignInForm />
    </Col>
    <Col
      xs={12}
      md={{ size: "6", offset: "1" }}
      lg={{ size: "5", offset: "2" }}
      xl={{ size: "6", offset: "2" }}
      className="d-none d-md-inline-block p-0"
    >
      <LazyLoadImage
        src={LoginImage}
        alt="logic"
        className="w-100"
        loading="lazy"
        effect="blur"
      />
    </Col>
  </Row>
);

export default SignIn;
