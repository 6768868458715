import axios from "axios";
import { toast } from "react-toastify";
import { API_URL } from "../api";
import {
  LOADING_FACTURAS,
  GET_FACTURAS_SUCCESS,
  GET_FACTURAS_FAILURE,
  SET_FACTURAS_PAGE,
  RESET_FACTURAS,
} from "./types/facturas";
import { LogOut } from "./auth";

export const getFacturas = (filters) => async (dispatch) => {
  dispatch({ type: LOADING_FACTURAS });

  try {
    const response = await axios.get(`${API_URL}/api/web/member/invoices`);

    dispatch({
      type: GET_FACTURAS_SUCCESS,
      payload: {
        facturas: response.data.data,
      },
    });

    return response;
  } catch (error) {
    dispatch({ type: GET_FACTURAS_FAILURE });

    if (error.response && error.response.status === 422) {
      toast.error(error.response.data.message);
    } else if (error.response && error.response.status === 401) {
      toast.error(error.response.data.message);
      dispatch(LogOut());
    } else {
      toast.error("Lo sentimos, ha sucedido un error inesperado al listar las facturas (Error 0006)");
    }
    return error;
  }
};

export const setPage = (page) => ({
  type: SET_FACTURAS_PAGE,
  payload: {
    page,
  },
});

export const resetFacturas = () => ({
  type: RESET_FACTURAS,
});
