import React from "react";
import GoogleMapReact from "google-map-react";

import MarkerImage from "../assets/img/marker.png";

const Marker = ({ name }) => (
  <img src={MarkerImage} alt={name} className="marker" />
);

const Maps = ({ markers, center, zoom }) => {
  // process.env.GOOGLE_MAPS <- ESTE API KEY SOBREPASA LA QUOTA
  // AIzaSyCw5tktV6kAyMfflARuosVjiEatvcbj4qI <- prestado

  return (
    <div className="googlemaps-wrapper">
      <GoogleMapReact 
        bootstrapURLKeys={{ key: "AIzaSyBar1w7RW6qKoHUmuLP6lorI7fRxK05SVo" }}
        defaultCenter={center}
        defaultZoom={zoom} 
      >
        {markers.map(marker => (
          <Marker
            key={marker.id}
            lat={marker.lat}
            lng={marker.lng}
            name={marker.name}
          />
        ))}
      </GoogleMapReact>
    </div>
  );
};

export default Maps;
