import React, { useEffect, useState, useCallback } from "react";
import { Row, Col, Container } from "reactstrap";

import { connect } from "react-redux";
import {
  getLaboratorios,
  resetLaboratorios
} from "../../redux/actions/laboratorios";

import PanelTitle from "../PanelTitle";
import Icon from "../../assets/img/laboratorio.svg";
import IframeC from "../IframeC";

const Laboratorios = ({
  laboratory,
  loading,
  history,
  getLaboratorios,
  filters,
  resetLaboratorios
}) => {
  const [fetchData, setFetchData] = useState(false);

  const getData = useCallback(async () => {
    const response = await getLaboratorios(filters);
    if (response !== 200) {
      history.push("/panel");
    }
  }, [getLaboratorios, history, filters]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (fetchData) {
      getData();
      setFetchData(false);
    }
  }, [fetchData, getData]);

  useEffect(() => {
    return () => resetLaboratorios();
  }, [resetLaboratorios]);

  return (
    <>
      <PanelTitle icon={Icon}>Laboratorios</PanelTitle>
      <h6 class="my-4">
        Tildar el resultado que desea descargar y después hacer click en
        “Descargar”.
      </h6>
      <Container>
        <Row noGutters>
          <Col>
            <IframeC url={laboratory} />
          </Col>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = state => ({
  laboratory: state.laboratorios.laboratory,
  loading: state.laboratorios.loading
});

export default connect(mapStateToProps, {
  getLaboratorios,
  resetLaboratorios
})(Laboratorios);
