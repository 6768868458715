import React from "react";
import { Row, Col } from "reactstrap";
import SlideShow from "./SlideShow";
import SlideInfo from "./SlideInfo";

const Header = () => {
  return (
    <Row className="row-eq-height mx-0 section__ppal" noGutters>
      <Col xs="12" sm="8">
        <SlideShow />
      </Col>
      <Col xs="12" sm="4">
        <SlideInfo />
      </Col>
    </Row>
  );
};

export default Header;
