import React from "react";
import PharmaciesItem from "./PharmaciesItem";

import { connect } from "react-redux";

const Container = ({ pharmacies }) => {
  return (
    <>
      {pharmacies.length === 0 ? (
        <>
          <h2>No hay farmacias, busca una.</h2>
        </>
      ) : (
        <>
          {pharmacies.map(pharmacy => (
            <PharmaciesItem key={pharmacy.id} pharmacy={pharmacy} />
          ))}
        </>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  pharmacies: state.pharmacies.pharmacies
});

export default connect(
  mapStateToProps,
  null
)(Container);
