import React, { useEffect, useState, useCallback } from "react";
import { Table, Row, Col } from "reactstrap";

import { connect } from "react-redux";
import { getTurnos, setPage, resetTurnos } from "../../redux/actions/turnos";

import PanelTitle from "../PanelTitle";
import Icon from "../../assets/img/turnos.svg";
import TableLoading from "../TableLoading";
import TurnosItem from "./TurnosItem";
import Paginator from "../Paginator";
import Button from "../Button";

const Turnos = ({
  turnos,
  loading,
  history,
  getTurnos,
  links,
  meta,
  filters,
  setPage,
  resetTurnos
}) => {
  const [fetchData, setFetchData] = useState(false);

  const getData = useCallback(async () => {
    const response = await getTurnos(filters);

    if (response.status !== 200) {
      history.push("/panel");
    }
  }, [getTurnos, history, filters]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handlePaginator = page => {
    setPage(page);
    setFetchData(true);
  };

  useEffect(() => {
    if (fetchData) {
      getData();
      setFetchData(false);
    }
  }, [fetchData, getData]);

  useEffect(() => {
    return () => resetTurnos();
  }, [resetTurnos]);

  return (
    <>
      <PanelTitle icon={Icon}>Turnos</PanelTitle>
      {loading ? (
        <div className="mt-5">
          <TableLoading />
        </div>
      ) : (
        <Row noGutters>
          <Col>
            {turnos.length === 0 ? (
              <h2 className="my-5">No se encontro nada, solicita tu turno.</h2>
            ) : (
              <>
                <Table responsive className="tables-dashboard mt-3 mt-sm-5">
                  <thead>
                    <tr>
                      <th scope="col">Médico y Especialidad</th>
                      <th scope="col">Fecha y Hora</th>
                    </tr>
                  </thead>
                  <tbody>
                    {turnos.map(item => (
                      <TurnosItem key={item.id} {...item} />
                    ))}
                  </tbody>
                </Table>
              </>
            )}

            <Row noGutters className="mx-0 mb-3">
              <Col>
                {turnos.length > 0 && (
                  <Paginator
                    handlePaginator={handlePaginator}
                    loading={loading}
                    currentPage={meta.currentPage}
                    first={links.first}
                    last={links.last}
                    next={links.next}
                    prev={links.prev}
                    center={true}
                  />
                )}
              </Col>
            </Row>
            <Row noGutters>
              <Col>
                <Button
                  type="orange"
                  url="https://turnos.elijasuturno.com.ar/turnos_php2/?es=alta"
                  external
                >
                  Solicitar Turno
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  turnos: state.turnos.turnos,
  loading: state.turnos.loading,

  links: state.turnos.links,
  meta: state.turnos.meta,
  filters: state.turnos.filters
});

export default connect(
  mapStateToProps,
  {
    getTurnos,
    setPage,
    resetTurnos
  }
)(Turnos);
