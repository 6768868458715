import React from "react";
import Tags from "../components/Meta/Tags";
import Banner from "../components/Banner";
import Content from "../components/EsteticaBienestar/Content";

import ImageBanner from "../assets/img/bienestar.jpg";

const ServiciosEsteticaBienestar = () => (
  <>
    <Tags 
      title="Estética y bienestar" 
      description="Todo en un solo lugar para verte y sentirte mejor." 
      keywords="rejuvenecimiento, relajacion, cuidado de la piel, tratamientos estetitcos, podologia, limpiez de cutis , peeling, punta de diamante, dermoabrasion , reflexologia" 
      canonical="/servicios-estetica-bienestar" />
    <Banner
      title="Servicios de Estética y Bienestar"
      image={ImageBanner}
      size="6"
      height="eb"
    />
    <Content />
  </>
);

export default ServiciosEsteticaBienestar;
