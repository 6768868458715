import React from "react";
import Select from "react-select";

import { connect } from "react-redux";
import { customStyles } from "./styles/common";

const LocationsSelect = ({ data, loading, onSelect, value }) => {
  return (
    <Select
      className="basic-single"
      classNamePrefix="custom-select"
      options={data}
      value={value}
      onChange={value => {
        onSelect(value);
      }}
      placeholder="Barrios"
      isLoading={loading}
      styles={customStyles}
      clearable={true}
      noOptionsMessage={() => "No se encontraron datos."}
    />
  );
};

const mapStateToProps = state => ({
  data: state.locations.data,
  loading: state.locations.loading
});

export default connect(
  mapStateToProps,
  null
)(LocationsSelect);
