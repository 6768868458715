import React, { useCallback, useState } from "react";
import { Row, Col, Form } from "reactstrap";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import { connect } from "react-redux";
import {
  searchPharmacies,
  setProvinceId,
  setAreaId,
  setLocationId,
  // COMMENT: ADD LINE
  setPharmaciesName,
} from "../../redux/actions/pharmacies";

import Button from "../SubmitBtn";
import PSelect from "../ProvincesSelect";
import ASelect from "../AreasSelect";
import LSelect from "../LocationsSelect";
// COMMENT: ADD LINE
import PNSelect from "../PharmaciesNameSelect";

import { toast } from "react-toastify";

var province_id_save = "";
var location_id_save = "";
var area_id_save = "";
var pharmacies_name_save = "";

const Searcher = ({
  loading,
  searchPharmacies,
  filters,
  setProvinceId,
  setAreaId,
  // COMMENT: ADD LINE
  setPharmaciesName,
  setLocationId,
}) => {
  const [values, setValue] = useState({
    area_id: null,
    location_id: null,
  });

  const fetchData = useCallback(() => {
    searchPharmacies(filters, true);
  }, [filters, searchPharmacies]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    fetchData();
  };

  const handleProvinceId = (id) => {
    setValue({
      ...values,
      area_id: null,
      location_id: null,
    });
    province_id_save = id;
    setProvinceId(id);
    setAreaId(null);
    setLocationId(null);
  };

  const handleAreasId = (value) => {
    setValue({
      ...values,
      area_id: value,
      location_id: null,
    });

    area_id_save = value.value;
    setAreaId(value.value);
  };

  const handleLocationsId = (value) => {
    setValue({
      ...values,
      location_id: value,
    });

    location_id_save = value.value;
    setLocationId(value.value);
  };
  // COMMENT: START CODE
  const handleChangeNamePharmacies = (name) => {
    setPharmaciesName(name);

    pharmacies_name_save = name;
  };
  // COMMENT: END CODE

  return (
    <Form onSubmit={handleSubmit} className="w-100">

      <Row
        className="mb-0 section_top_responsive justify-content-center w-100"
        noGutters
      >
        <Col xs="12" sm="5" className="px-1 my-1 my-sm-1">
          <PNSelect onSelect={handleChangeNamePharmacies} />
        </Col>

        <Col xs="12" sm="5" className="px-1 my-1 my-sm-1">
          <PSelect value={province_id_save} onSelect={handleProvinceId} />
        </Col>
        <Col xs="12" sm="5" className="px-1 my-1 my-sm-1">
          <ASelect onSelect={handleAreasId} value={values.area_id} />
        </Col>
        <Col xs="12" sm="5" className="px-1 my-1 my-sm-1">
          <LSelect onSelect={handleLocationsId} value={values.location_id} />
        </Col>

      </Row>

      <Row noGutters className="mb-5 section_top_responsive justify-content-center w-100">

        <Col xs="12" sm="12" className="px-1 my-1 my-sm-1">

          <Link className="float-right ml-2 mr-5 btn-orange_outline" 
            to="#"
            onClick={() => {
              
              let contentcopy = 'https://altasalud.com.ar/farmacias?';

              if (province_id_save !== "" || area_id_save != "" || location_id_save != "" || pharmacies_name_save != "") {
                contentcopy +=
                  "province_id=" +
                  province_id_save +
                  "&area_id=" +
                  area_id_save +
                  "&location_id=" +
                  location_id_save +
                  "&pharmacies_name=" +
                  pharmacies_name_save;

                navigator.clipboard.writeText(contentcopy);

                //alert ("Link Copiado: "+contentcopy);
              } else {

                //toast.error("Debe seleccionar al menos una provincia para compartir la cartilla.");
                navigator.clipboard.writeText(contentcopy);
              }

              toast.success("Link Copiado: " + contentcopy);
            }}
          >
            {" "}
            
            ➩ Compartir

          </Link>
          
          <Button color="orange" text="Buscar" loading={loading} className="float-right" />

        </Col>

      </Row>

    </Form>
  );
};

const mapStateToProps = (state) => ({
  filters: state.pharmacies.filters,
  loading: state.pharmacies.loading,
});

export default connect(mapStateToProps, {
  searchPharmacies,
  setProvinceId,
  setLocationId,
  setAreaId,
  setPharmaciesName,
})(Searcher);
