export const customStyles = {
  control: (base, state) => ({
    ...base,
    boxShadow: "none"
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected ? "#F15A29" : "",
    color: state.isSelected ? "#fff" : "",
    ":active": {
      backgroundColor: state.isSelected ? "#ecf0f1" : ""
    },
    ":hover": {
      backgroundColor: state.isSelected ? "" : "#ecf0f1"
    }
  })
};
