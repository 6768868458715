import axios from "axios";
import { toast } from "react-toastify";
import { API_URL } from "../api";
import {
  LOADING_FORMTYPES,
  GET_FORMTYPES_SUCCESS,
  GET_FORMTYPES_FAILURE,
  RESET_FORMTYPES
} from "../actions/types/form_types";
import AlphabeticOrder from "../../lib/AlphabeticOrder";
import { store } from "../index";

export const getFormTypes = (
  isLoggedIn = false,
  isContactForm
) => async dispatch => {
  dispatch({ type: LOADING_FORMTYPES });

  const { user } = store.getState().auth;

  try {
    const response = await axios.get(
      `${API_URL}/api/web/contact_form_type?pagination=50`
    );

    dispatch({
      type: GET_FORMTYPES_SUCCESS,
      payload: {
        formTypes: AlphabeticOrder(response.data.data, "name")
          .filter(item => {
            if (user && isContactForm) {
              if (item.loggedin === 0) {
                return item;
              }
            } else if (!user && isContactForm) {
              if (item.loggedin === 0) {
                return item;
              }
            } else {
              if (item.loggedin === 1 && isLoggedIn) {
                return item;
              }

              if (item.loggedin === 0 && !isLoggedIn) {
                return item;
              }
            }

            return null;
          }, [])
          .map((item, index) => ({
            value: item.id,
            label: `${index + 1} - ${item.name}`
          }))
      }
    });

    return response.status;
  } catch (error) {
    dispatch({ type: GET_FORMTYPES_FAILURE });

    if (error.response && error.response.status === 422) {
      toast.error(error.response.data.message);
    } else if (error.response && error.response.status === 401) {
      toast.error(error.response.data.message);
    } else {
      toast.error("Lo sentimos, ha sucedido un error inesperado. (Error 0002)");
    }

    if (error.response) {
      return error.response.status;
    } else {
      return error;
    }
  }
};

export const resetFormTypes = () => ({
  type: RESET_FORMTYPES
});
