import React from "react";
import { SocialIcon } from "react-social-icons";

const WhatsAppFloatingButton = ({href, phoneNumber, appAbsent}) => {
    //href="whatsapp://send?phone=+5491159244301" // desde movil
    //const baseUrl = href ? href : "https://wa.me/";
    const baseUrl = href ? href : "https://web.whatsapp.com/send";
    const sinApp = appAbsent ? appAbsent : '1';
    
    const enlace = baseUrl + '?phone=' + phoneNumber + '&app_absent=' + sinApp; // desde pc de escritorio

    return (
        <div>
            <a
                href={enlace}
                className="whatsapp_float"
                target="_blank"
                rel="noopener noreferrer"
            >
                <i className="fa fa-whatsapp whatsapp-icon"></i>

                <div className="whatsapp_float_txt">
                    ¡Quiero asociarme!
                </div>
            </a>
        </div>
    );
};

export default WhatsAppFloatingButton;
