import React from "react";
import Tags from "../components/Meta/Tags";
import Banner from "../components/Banner";
import Content from "../components/RRHH/Content";

import ImageBanner from "../assets/img/rr-hh.jpg";

const RRHH = () => {
  return (
    <>
      <Tags 
        title="Trabaja con nosotros" 
        description="Unite al equipo de alta salud para brindar la mejor calidad de servicio a nuestros afiliados." 
        keywords="curriculum, administrativos, medicos" 
        canonical="/trabaja-con-nosotros" />
      <Banner title="Trabajá con nosotros" image={ImageBanner} siz={5} />
      <Content />
    </>
  );
};

export default RRHH;
