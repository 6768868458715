import { TOGGLE_SIDEBAR } from "../actions/types/common";

const initialState = {
  sidebarOpen: true
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR: {
      return {
        ...state,
        sidebarOpen: !state.sidebarOpen
      };
    }

    default: {
      return state;
    }
  }
};
