import React from "react";

import { Badge } from "reactstrap";
import Moment from "react-moment";
import Downloadbtn from "../../assets/img/download.svg";
import { $CombinedState } from "redux";

var impa = 0;



const FacturasItem = ({ fecha, importe, estado, file, download }) => (



  <tr>
    <td>
      <Moment locale="es" format="LL">
        {fecha}
      </Moment>
    </td>
    <td>${importe}</td>
    <td color={`${estado === "Impago" ? impa++ : ""}`} >
      <Badge color={`${estado === "Impago" ? "danger" : estado === "Parcial" ? "warning"  : "success"}`} pill>
        {estado}
      </Badge>
    </td>
    <td>

    {download === "no" && estado!=="Saldado" ? 
    
    "Regularice su cuenta corriente comunicándose con el Sector Facturación al 4588-5900 o facturacion@altasalud.com.ar"
      
    
    : 
    
    <a
    href={file}
    download="Facturas Web"
    className="d-block factu"
    target="_blank"
    rel="noopener noreferrer"
    id={`factu${impa}`}        
  >
    {" "}
    <img src={Downloadbtn} alt="download icon" className="print-icon-tables" />
    Descargar
  </a>
  
  }
  
  
      
      
    </td>
  </tr>
);

export default FacturasItem;
