import React from "react";
import { Row, Col } from "reactstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Banner = ({ title, image, subtitle, size, height }) => (
  <Row className="mx-0 section__ppal">
    <Col xs="12" className="p-0 container__section-banners">
      <Row className="mx-0 min-banner-ppal">
        <Col xs="12" className="p-0">
          <LazyLoadImage
            alt="alt"
            effect="blur"
            src={image}
            className={`w-100 image__section-banners image__banner--secondary ${height}`}
          />
          <div className="overlay-img-sections" />
        </Col>
        <Col
          xs="12"
          sm={size ? size : "6"}
          lg={size ? size : "4"}
          className="titles-float-profile-img d-flex align-items-center"
        >
          <div className="d-flex flex-column">
            <hr className="section-banner_info__line" />
            <h1 className="section-banner__title">{title}</h1>
            {subtitle && <p className="section-banner__text">{subtitle}</p>}
          </div>
        </Col>
      </Row>
    </Col>
  </Row>
);

export default Banner;
